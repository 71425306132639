import Cookies from 'js-cookie';

export const isLoggedIn = () => {
  return !!Cookies.get('loggedInUser');
};

export const getCookie = (nameCookie) => {
  return Cookies.get(nameCookie);
};
export const removeCookie = (nameCookie) => {
  return Cookies.remove(nameCookie);
};
export const runExistingScripts = (dispatch, toggleCheck, isCheck) => {
  const scripts = document.querySelectorAll('script[data-existing-script]');
  scripts.forEach((script) => {
    if (script) {
      const newScript = document.createElement('script');
      newScript.type = 'text/javascript';
      newScript.src = script.src;
      document.head.appendChild(newScript);
    }
  });

  const scripts1 = document.querySelectorAll('script[data-script]');
  scripts1.forEach((script) => {
    if (script) {
      if (!isCheck) {
        dispatch(toggleCheck(true));
      } else {
        const newScript = document.createElement('script');
        newScript.type = 'text/javascript';
        newScript.src = script.src;
        document.head.appendChild(newScript);
      }
    }
  });
};
export const setCookie = (nameCookie, valueCookie, expirationCookie = 0) => {
  let setCookie;
  if (expirationCookie === 0) {
    setCookie = Cookies.set(nameCookie, valueCookie);
  } else {
    setCookie = Cookies.set(nameCookie, valueCookie, {
      expires: expirationCookie,
    });
  }
  return setCookie;
};

export const getDate = (millisecond) => {
  const timestamp = millisecond;
  const date = new Date(timestamp);
  const options = {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: true,
  };
  const formattedDate = date.toLocaleString('en-US', options);
  return formattedDate;
};

export const convertToMilliseconds = (value, unit) => {
  const millisecondsPerDay = 24 * 60 * 60 * 1000;
  if (unit === 'Hours') {
    const milliseconds = value * 60 * 60 * 1000;
    return Math.floor(Date.now() - milliseconds);
  } else if (unit === 'Days') {
    const milliseconds = value * millisecondsPerDay;
    return Math.floor(Date.now() - milliseconds);
  } else if (unit === 'Weeks') {
    const milliseconds = value * 7 * millisecondsPerDay;
    return Math.floor(Date.now() - milliseconds);
  } else if (unit === 'Months') {
    const milliseconds = value * 30 * millisecondsPerDay;
    return Math.floor(Date.now() - milliseconds);
  }

  return 0;
};

export const formatTimestamp = (timestamp) => {
  const date = new Date(timestamp * 1000);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  return `${year}-${month}-${day}T${hours}:${minutes}`;
};

export const ChartDashBoardAll = (dataFecth) => {
  let dataDemo24h = [];
  let getHoursNow = new Date().getHours();
  let hoursPluss = getHoursNow;
  for (let index = 0; index < 24; index++) {
    dataDemo24h.push({
      date: `${hoursPluss}:${hoursPluss >= 12 ? 'PM' : 'AM'}`,
      market1: '',
      market2: '',
      market3: '',
      market4: '',
      market5: '',
      market6: '',
    });
    if (hoursPluss === 0) {
      hoursPluss = 23;
    } else {
      hoursPluss--;
    }
  }
  let timedemo = new Date(1704960219188);
  // console.log('time demo :', timedemo);
  let dataTrans = [];
  for (let index = 0; index < 24; index++) {
    let dataArray = dataDemo24h.pop();
    dataTrans.push(dataArray);
  }

  dataTrans.map((data2) => {
    let countJob = 0;
    let countJobFailed = 0;
    let countOnDemand = 0;
    let countBatch = 0;
    let countBatchFailed = 0;
    let countOnDemandFailed = 0;
    dataFecth.map((data) => {
      let getHour = new Date(data.startTimestamp).getHours();

      if (getHour === parseInt(data2.date)) {
        countJob++;
        if (data.status === 'Failed') {
          countJobFailed++;
        }
        if (data.workflowPath === 'On Demand') {
          countOnDemand++;
          if (data.status === 'Failed') {
            countOnDemandFailed++;
          }
        }
        if (data.workflowGroup === 'BATCH' || data.workflowPath === 'Batch') {
          countBatch++;
          if (data.status === 'Failed') {
            countBatchFailed++;
          }
        }
      }
    });
    data2.market1 = countJob;
    data2.market2 = countJobFailed;
    data2.market3 = countOnDemand;
    data2.market4 = countBatch;
    data2.market5 = countBatchFailed;
    data2.market6 = countOnDemandFailed;
  });

  var chartAll = window.AmCharts.makeChart('deal-analytic-chart', {
    type: 'serial',
    theme: 'light',
    dataDateFormat: 'YYYY-MM-DD HH',
    precision: 0,
    valueAxes: [
      {
        id: 'v1',
        position: 'left',
        autoGridCount: false,
        labelFunction: function (value) {
          return '$' + Math.round(value) + 'M';
        },
      },
      {
        id: 'v2',
        gridAlpha: 0,
        autoGridCount: false,
      },
    ],
    graphs: [
      {
        id: 'g1',
        valueAxis: 'v2',
        bullet: 'round',
        bulletBorderAlpha: 1,
        bulletColor: '#FFFFFF',
        bulletSize: 8,
        hideBulletsCount: 50,
        lineThickness: 3,
        lineColor: '#2162ed',
        type: 'smoothedLine',
        title: 'All',
        useLineColorForBulletBorder: true,
        valueField: 'market1',
        balloonText: "[[title]]<br /><b style='font-size: 130%'>[[value]]</b>",
      },
      {
        id: 'g6',
        valueAxis: 'v2',
        bullet: 'round',
        bulletBorderAlpha: 1,
        bulletColor: '#FFFFFF',
        bulletSize: 8,
        hideBulletsCount: 50,
        lineThickness: 3,
        lineColor: '#ed3c21',
        type: 'smoothedLine',
        title: 'Failed',
        useLineColorForBulletBorder: true,
        valueField: 'market2',
        balloonText: "[[title]]<br /><b style='font-size: 130%'>[[value]]</b>",
        hidden: true,
      },
      {
        id: 'g3',
        valueAxis: 'v2',
        bullet: 'round',
        bulletBorderAlpha: 1,
        bulletColor: '#FFFFFF',
        bulletSize: 8,
        hideBulletsCount: 50,
        lineThickness: 3,
        lineColor: '#35ed21',
        type: 'smoothedLine',
        title: 'On Demand All',
        useLineColorForBulletBorder: true,
        valueField: 'market3',
        balloonText: "[[title]]<br /><b style='font-size: 130%'>[[value]]</b>",
        hidden: true,
      },
      {
        id: 'g2',
        valueAxis: 'v2',
        bullet: 'round',
        bulletBorderAlpha: 1,
        bulletColor: '#FFFFFF',
        bulletSize: 8,
        hideBulletsCount: 50,
        lineThickness: 3,
        lineColor: '#21ede6',
        type: 'smoothedLine',
        title: 'On Demand Failed',
        useLineColorForBulletBorder: true,
        valueField: 'market6',
        balloonText: "[[title]]<br /><b style='font-size: 130%'>[[value]]</b>",
        hidden: true,
      },

      {
        id: 'g4',
        valueAxis: 'v2',
        bullet: 'round',
        bulletBorderAlpha: 1,
        bulletColor: '#FFFFFF',
        bulletSize: 8,
        hideBulletsCount: 50,
        lineThickness: 3,
        lineColor: '#ede621',
        type: 'smoothedLine',
        title: 'Batch All',
        useLineColorForBulletBorder: true,
        valueField: 'market4',
        balloonText: "[[title]]<br /><b style='font-size: 130%'>[[value]]</b>",
        hidden: true,
      },
      {
        id: 'g5',
        valueAxis: 'v2',
        bullet: 'round',
        bulletBorderAlpha: 1,
        bulletColor: '#FFFFFF',
        bulletSize: 8,
        hideBulletsCount: 50,
        lineThickness: 3,
        lineColor: '#d221ed',
        type: 'smoothedLine',
        title: 'Batch Failed',
        useLineColorForBulletBorder: true,
        valueField: 'market5',
        balloonText: "[[title]]<br /><b style='font-size: 130%'>[[value]]</b>",
        hidden: true,
      },
    ],
    chartCursor: {
      pan: true,
      valueLineEnabled: true,
      valueLineBalloonEnabled: true,
      cursorAlpha: 0,
      valueLineAlpha: 0.2,
    },
    chartScrollbar: {
      autoGridCount: true,
      graph: 'g1',
      oppositeAxis: true,
      scrollbarHeight: 40,
    },
    categoryField: 'date',
    categoryAxis: {
      dashLength: 1,
      minorGridEnabled: true,
    },
    legend: {
      useGraphSettings: true,
      position: 'top',
    },
    balloon: {
      borderThickness: 1,
      shadowAlpha: 0,
    },
    dataProvider: dataTrans,
  });
};
export const ChartPieLatestBatch = (dataFecth) => {
  let dataTrans = [];
  dataTrans.push({ type: 'Foreign', value: dataFecth[0].sum_foreignsheet });
  dataTrans.push({ type: 'Normal zone (Domestic)', value: dataFecth[0].sum_normalzonesheet });
  dataTrans.push({ type: 'Low zone (Domestic)', value: dataFecth[0].sum_lowZonesheet });
  var chartPie = window.AmCharts.makeChart('allocation-chart', {
    type: 'pie',
    startDuration: 0,
    theme: 'light',
    labelRadius: 0,
    pullOutRadius: 0,
    labelText: '[[country]]',
    colorField: 'color',
    legend: {
      enabled: true,
      position: 'left',
    },
    innerRadius: '30%',
    dataProvider: dataTrans,
    // dataProvider: [{
    //   country: "Lithuania",
    //   litres: 501.9,
    //   color: "#85C5E3"
    // }, {
    //   country: "Czech Republic",
    //   litres: 301.9,
    //   color: "#6AA3C4"
    // }, {
    //   country: "Ireland",
    //   litres: 201.1,
    //   color: "#6097B9"
    // }, {
    //   country: "india",
    //   litres: 220.1,
    //   color: "#4E81A4"
    // }],
    valueField: 'value',
    titleField: 'type',
  });
};
// chart document
const createData = (timesDb, timesDoc, type, fromTimes = 0, toTimes = 0) => {
  const data = [];
  let end, start;

  const config = {
    OneDay: {
      adjustStart: (start) => start.setHours(end.getHours() - 24),
      interval: 1000 * 60 * 60,
    },
    OneWeek: {
      adjustStart: (start) => start.setHours(end.getHours() - 24 * 7),
      interval: 1000 * 60 * 60,
    },
    OneMonth: {
      adjustStart: (start) => start.setDate(end.getDate() - 30),
      interval: 1000 * 60 * 60 * 24,
    },
    OneYear: {
      adjustStart: (start) => start.setFullYear(end.getFullYear() - 1),
      interval: 1000 * 60 * 60 * 24,
    },
  };

  const { adjustStart, interval } = config[type];
  if (!adjustStart) {
    throw new Error('Invalid type');
  }
  if (fromTimes !== 0 && toTimes !== 0) {
    start = new Date(fromTimes);
    end = new Date(toTimes);
  } else {
    end = new Date();
    start = new Date(end);
    adjustStart(start);
  }

  const timeDiff = end.getTime() - start.getTime();
  const steps = Math.floor(timeDiff / interval);

  for (let i = 0; i <= steps; i++) {
    const current = new Date(start.getTime() + i * interval);

    if (type === 'OneWeek' && ![0, 12].includes(current.getHours())) {
      continue;
    }

    const formattedDate = `${current.getFullYear()}-${String(current.getMonth() + 1).padStart(2, '0')}-${String(
      current.getDate(),
    ).padStart(2, '0')}${
      ['OneDay', 'OneWeek'].includes(type) ? ` ${String(current.getHours()).padStart(2, '0')}:00` : ''
    }`;
    data.push({ date: formattedDate, market1: 0, market2: 0, market3: 0 });
  }
  // jobs
  timesDb?.forEach((timeDb) => {
    const itemDate = new Date(timeDb);
    if (itemDate >= start && itemDate <= end) {
      let index;
      if (type === 'OneWeek') {
        index = Math.floor((itemDate - start) / (1000 * 60 * 60 * 24));
      } else {
        index = Math.floor((itemDate - start) / interval);
      }
      if (index >= 0 && index < data.length) {
        console.log(index);
        data[index].market1++;
      }
    }
  });
  //documents
  timesDoc?.forEach((timeDb) => {
    const itemDate = new Date(timeDb);
    if (itemDate >= start && itemDate <= end) {
      let index;
      if (type === 'OneWeek') {
        index = Math.floor((itemDate - start) / (1000 * 60 * 60 * 24));
      } else {
        index = Math.floor((itemDate - start) / interval);
      }
      if (index >= 0 && index < data.length) {
        data[index].market2++;
      }
    }
  });

  return data;
};

const getTypeFromTimeRange = (fromTimes, toTimes) => {
  const fromDate = new Date(fromTimes);
  const toDate = new Date(toTimes);

  if (toDate < fromDate) {
    return null;
  }

  const maxDateRange = {
    OneDay: 3,
    OneWeek: 7,
    OneMonth: 30,
    OneYear: 365,
  };

  const diffDays = (toDate - fromDate) / (1000 * 60 * 60 * 24);
  const type = Object.keys(maxDateRange).find((type) => diffDays <= maxDateRange[type]);

  return type || 'OneYear';
};

export const ChartDashBoardAll2 = (dataFecth, dataDocument = [], type = 'OneDay', fromTimes = 0, toTimes = 0) => {
  let adjustedType = type;
  if (fromTimes !== 0 && toTimes !== 0) {
    adjustedType = getTypeFromTimeRange(fromTimes, toTimes);
  }

  const dataChart = createData(dataFecth, dataDocument, adjustedType, fromTimes, toTimes);
  console.log('dataChart ::', dataChart);
  window.AmCharts.makeChart('deal-analytic-chart-2', {
    type: 'serial',
    theme: 'light',
    precision: 2,
    valueAxes: [
      {
        id: 'v1',
        position: 'left',
        autoGridCount: false,
        labelFunction: function (value) {
          return '$' + Math.round(value) + 'M';
        },
      },
      {
        id: 'v2',
        gridAlpha: 0,
        autoGridCount: false,
      },
    ],
    graphs: [
      {
        id: 'g1',
        valueAxis: 'v2',
        bullet: 'round',
        bulletBorderAlpha: 1,
        bulletColor: '#FFFFFF',
        bulletSize: 8,
        hideBulletsCount: 50,
        lineThickness: 3,
        lineColor: '#2162ed',
        type: 'smoothedLine',
        title: 'Jobs',
        useLineColorForBulletBorder: true,
        valueField: 'market1',
        balloonText: "[[title]]<br /><b style='font-size: 130%'>[[value]]</b>",
      },
      {
        id: 'g2',
        valueAxis: 'v2',
        bullet: 'round',
        bulletBorderAlpha: 1,
        bulletColor: '#FFFFFF',
        bulletSize: 8,
        hideBulletsCount: 50,
        lineThickness: 3,
        lineColor: '#ed3c21',
        type: 'smoothedLine',
        title: 'Documents',
        useLineColorForBulletBorder: true,
        valueField: 'market2',
        balloonText: "[[title]]<br /><b style='font-size: 130%'>[[value]]</b>",
        hidden: true,
      },
    ],
    chartCursor: {
      pan: true,
      valueLineEnabled: true,
      valueLineBalloonEnabled: true,
      cursorAlpha: 0,
      valueLineAlpha: 0.2,
    },
    chartScrollbar: {
      autoGridCount: true,
      graph: 'g1',
      oppositeAxis: true,
      scrollbarHeight: 40,
    },
    categoryField: 'date',
    categoryAxis: {
      parseDates: true,
      minPeriod: 'hh',
      dashLength: 1,
      minorGridEnabled: true,
      dateFormats: [
        { period: 'fff', format: 'JJ:NN:SS' },
        { period: 'ss', format: 'JJ:NN:SS' },
        { period: 'mm', format: 'JJ:NN' },
        { period: 'hh', format: 'JJ:NN' },
        { period: 'DD', format: 'MMM DD' },
        { period: 'WW', format: 'MMM DD' },
        { period: 'MM', format: 'MMM' },
        { period: 'YYYY', format: 'YYYY' },
      ],
    },
    legend: {
      useGraphSettings: true,
      position: 'top',
    },
    balloon: {
      borderThickness: 1,
      shadowAlpha: 0,
    },
    dataProvider: dataChart,
  });
};
