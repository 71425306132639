import { Header, Siderbar } from '../components';

function DefaultLayout({ children }) {
  return (
    <div className="pcoded-container navbar-wrapper">
      <Header />
      <div className="pcoded-main-container">
        <div className="pcoded-wrapper">
         
          <div>{children}</div>
        </div>
      </div>
    </div>
  );
}

export default DefaultLayout;
