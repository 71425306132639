import config from '../config';
//layout
import { DefaultLayout, LoginLayout } from '../layouts';
//page
import {
  Default,
  Products,
  Login,
  UserManager,
  Company
} from '../pages';

//Public
const publicRoutes = [{ path: config.routes.login, component: Login, layout: LoginLayout }];

const privateRoutes = [
  { path: config.routes.dashboard, title: 'TLS | Dashboard', component: Default, layout: DefaultLayout },
  { path: config.routes.product, title: 'TLS | Products', component: Products, layout: DefaultLayout },
  { path: config.routes.user, title: 'TLS | Users', component: UserManager, layout: DefaultLayout },
  { path: config.routes.company, title: 'TLS | Company', component: Company, layout: DefaultLayout },
];

export { publicRoutes, privateRoutes };
