import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useLayoutEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { HeaderContent } from '../../component/page';
import { useStore, actions, handles } from '../../store';
import Alert from '../../component/Alert';

function UserManager() {
  //store
  const { state, dispatch } = useStore();
  const { hubConnection, isCheckScript } = state;
  const { toggleCheckScript } = actions;
  const { runExistingScripts } = handles;
  // use ref
  const inputUserNameRef = useRef(null);
  
  const inputPasswordRef = useRef(null);
  
  const inputFullNameRef = useRef(null);
  
  const inputEmailNameRef = useRef(null);
  
  // use ref 2

  // useState
  const [dataUser, setDataUser] = useState();

  const [isAddcompleted, setIsAddcompleted] = useState(false);
  const [dataLicense, setDataLicense] = useState();
  const [maxUserReScale, setmaxUserReScale] = useState();
  const [maxUserReSort, setmaxUserReSort] = useState();
  const [maxUserPnD, setmaxUserPnD] = useState();
  const [currUserReScale, setcurrUserReScale] = useState(0);
  const [currUserReSort, setcurrUserReSort] = useState(0);
  const [currUserPnD, setcurrUserPnD] = useState(0);
  let navigate = useNavigate();
  const userLogin = JSON.parse(Cookies.get('loggedInUser'));
  //useEffect
  useLayoutEffect(() => {
    runExistingScripts(dispatch, toggleCheckScript, isCheckScript, true);
    // eslint-disable-next-line
  }, []);
  // onChange Connect
  useEffect(() => {
    if (hubConnection) {
      hubConnection.invoke('ReadEmailUserByUserName', userLogin.user).catch((err) => console.log(err));

      hubConnection.on('SendReadEmailUserByUserName', (param) => {

        if (param.appRole === null || !param.appRole.includes("tlsAdmin")) {
          navigate("/dashboard");
        }
      });
      //invoke
      hubConnection.invoke('ReadUsers').catch((err) => console.log(err));

      //on
      hubConnection.on('SendReadUsers', (users) => {

        setDataUser(users);
       
        setcurrUserReSort(users.filter(x => x.appAccess.includes("reSort")).length);
        setcurrUserReScale(users.filter(x => x.appAccess.includes("reScale")).length);
        setcurrUserPnD(users.filter(x => x.appAccess.includes("PnD")).length);

      });
      hubConnection.on('SendCrudUsers', (userResponse) => {
       // console.log(userResponse);
        if (userResponse.isSuccessStatusCode) {
          if (userResponse.put) {
            console.log('update');
            setIsAddcompleted(true);
          } else if (userResponse.post) {
            console.log('add');
            setIsAddcompleted(true);
          } else if (userResponse.delete) {
            console.log('delete');
          }
          hubConnection.invoke('ReadUsers').catch((err) => console.log(err));
        }
      });


      hubConnection.invoke('ReadLicense').catch((err) => console.log("lic error: " + err));
      hubConnection.on('SendReadLicense', (param) => {
        // console.log('param key:', param);
        const dataLicenseKey = JSON.parse(param);
        // console.log('license key:', );
        setmaxUserReScale(dataLicenseKey.Applications.filter((auto) => auto.Application.includes("reScale"))[0].User)
        setmaxUserReSort(dataLicenseKey.Applications.filter((auto) => auto.Application.includes("reSort"))[0].User)
        setmaxUserPnD(dataLicenseKey.Applications.filter((auto) => auto.Application.includes("PnD"))[0].User)
        setDataLicense(dataLicenseKey);
      });
    }

  }, [hubConnection]);
  // console.log(dataUser);
  const handleChangePassword = () => {
    document.getElementById('pass-word-edit').style.display = 'block';
    document.getElementById('btn-show-pass').style.display = 'none';
  };

  // add user
  // console.log(dataUser);
  const checkValidate = (elementInput, elementMessage, message) => {
    elementMessage.style.display = 'block';
    elementMessage.style.color = 'red';
    elementMessage.style.fontSize = '13px';
    elementMessage.style.paddingLeft = '5px';
    if (elementInput === '') {
      elementMessage.innerText = `${message} can't be blank !`;
      return false;
    }
    else if (message === "DupClient") {
      var checkExist = dataUser.filter(x => x.user.toUpperCase() === elementInput.toUpperCase()).length > 0 ? false : true
      if (checkExist === false) {
        elementMessage.innerText = `${elementInput} already exists !`;
        return false;
      }
      return true;
    }
    else if (message === 'Email') {
      if (!elementInput.includes('@')) {
        elementMessage.innerText = `Incorrect email format !`;
        return false;
      } else {
        elementMessage.style.display = 'none';
        return true;
      }
    }
    else {
      elementMessage.style.display = 'none';
      return true;
    }
  };
  const checkValidate2 = (elementInput1, elementInput12, elementMessage, message) => {
    elementMessage.style.display = 'block';
    elementMessage.style.color = 'red';
    elementMessage.style.fontSize = '13px';
    elementMessage.style.paddingLeft = '5px';
    if (message === 'reSort') {
      if (elementInput1) {
        if (!elementInput12) {
          elementMessage.innerText = 'reSort user limit is reached';
          return false;
        }
        elementMessage.style.display = 'none';
        return true;
      }
      else {
        elementMessage.style.display = 'none';
        return true;
      }
    }
    else if (message === 'reScale') {
      if (elementInput1) {
        if (!elementInput12) {
          elementMessage.innerText = 'reScale user limit is reached';
          return false;
        }
        elementMessage.style.display = 'none';
        return true;
      }
      else {
        elementMessage.style.display = 'none';
        return true;
      }
    }
    else {
      elementMessage.style.display = 'none';
      return true;
    }
  };
  const handleSendDataToModalADD = () => {
    document.getElementById('ckreSortADD').checked = false;
    document.getElementById('ckreScaleADD').checked = false;
    document.getElementById('ckreSortADD_Admin').checked = false;
    document.getElementById('ckreScaleADD_Admin').checked = false;
    document.getElementById('ckreSortADD_Admin').disabled = true;
    document.getElementById('ckreScaleADD_Admin').disabled = true;
    document.getElementById('ckTLSADD_Admin').checked = false;
    document.getElementById('appAccessMessage').style.display = 'none';
    document.getElementById('appAccessMessage2').style.display = 'none';
    document.getElementById('email-message').style.display = 'none';
    document.getElementById('fullName-message').style.display = 'none';
    document.getElementById('passWord-message').style.display = 'none';
    document.getElementById('message-user').style.display = 'none';
    //email-message fullName-message passWord-message message-user
  }
  const handleSendDataToModal = (index) => {
    let dataUserTarget = dataUser.at(index);
    document.getElementById('user-name-edit').value = dataUserTarget.user;

    document.getElementById('pass-word-edit').value = atob(dataUserTarget.password);
    document.getElementById('full-name-edit').value = dataUserTarget.fullName;
    document.getElementById('email-edit').value = dataUserTarget.email;
    document.getElementById('ckreScaleEDIT').checked = String(dataUserTarget.appAccess).includes("reScale");
    document.getElementById('ckreSortEDIT').checked = String(dataUserTarget.appAccess).includes("reSort");
    document.getElementById('ckPnDEDIT').checked = String(dataUserTarget.appAccess).includes("PnD");

    document.getElementById('ckTLSEDIT_Admin').checked = String(dataUserTarget.appRole).includes("tlsAdmin");
    document.getElementById('ckreScaleEDIT_Admin').checked = String(dataUserTarget.permission).includes("admin");
    document.getElementById('ckreSortEDIT_Admin').checked = String(dataUserTarget.appRole).includes("reSortAdmin");
    document.getElementById('ckPnDEDIT_Admin').checked = String(dataUserTarget.appRole).includes("PnDAdmin");

    document.getElementById('ckreSortEDIT_Admin').disabled = !document.getElementById('ckreSortEDIT').checked;
    document.getElementById('ckreScaleEDIT_Admin').disabled = !document.getElementById('ckreScaleEDIT').checked;
    document.getElementById('ckPnDEDIT_Admin').disabled = !document.getElementById('ckPnDEDIT').checked;

    document.getElementById('appAccessMessage3').style.display = 'none';
    document.getElementById('appAccessMessage4').style.display = 'none';
  };
  // add user
  const handleAddUser = () => {
    let userName = inputUserNameRef.current.value;
    let messageUser = document.getElementById('message-user');
    checkValidate(userName, messageUser, 'User name');
    console.log(checkValidate(userName, messageUser, 'User name'));
    //
    let passWord = inputPasswordRef.current.value;
    let messagePass = document.getElementById('passWord-message');
    checkValidate(passWord, messagePass, 'Pass word');
    //
    let fullName = inputFullNameRef.current.value;
    let messageFullName = document.getElementById('fullName-message');
    checkValidate(fullName, messageFullName, 'Full name');
    //
    let emailUser = inputEmailNameRef.current.value;
    let messageEmailUser = document.getElementById('email-message');
    checkValidate(emailUser, messageEmailUser, 'Email');

    // init checkbox  ckreSortADD_Basic



    //
    let messageAppAccess = document.getElementById('appAccessMessage');
    let messageAppAccess2 = document.getElementById('appAccessMessage2');
    let chkreScale = document.getElementById('ckreScaleADD').checked;
    //checkValidate(chkreScale, messageAppAccess, 'Access');
    let chkreSort = document.getElementById('ckreSortADD').checked;
    let chkPnD = document.getElementById('ckPnDADD').checked;

    let reScaleOK = chkreScale && currUserReScale < maxUserReScale
    let reSortOK = chkreSort && currUserReSort < maxUserReSort
    let PnDOK = chkPnD && currUserPnD < maxUserPnD
    let appAccessStr = []
    if (reScaleOK) {
      appAccessStr.push('reScale')
    }
    if (reSortOK) {
      appAccessStr.push('reSort')
    }
    if (PnDOK) {
      appAccessStr.push('PnD')
    }
    //appAccessStr += appAccessStr === "" ? "":","
    // if (reSortOK && reScaleOK) {
    //   appAccessStr = '["reScale", "reSort"]'
    // }
    // else {
    //   if (reSortOK) {
    //     appAccessStr = '["reSort"]'
    //   }
    //   if (reScaleOK) {
    //     appAccessStr = '["reScale"]'
    //   }

    // }

    let chkreScaleAdmin = document.getElementById('ckreScaleADD_Admin').checked;
    //checkValidate(chkreScale, messageAppAccess, 'Access'); 
    let chkreSortAdmin = document.getElementById('ckreSortADD_Admin').checked;
    let chkTLSAdmin = document.getElementById('ckTLSADD_Admin').checked;
    let chkPnDAdmin = document.getElementById('ckPnDADD_Admin').checked;
    let appRolestr = []
    if (chkreSortAdmin) {
      appRolestr.push('reSortAdmin')
    }
    if (chkTLSAdmin) {
      appRolestr.push('tlsAdmin')
    }
    if(chkPnDAdmin)
    {
      appRolestr.push('PnDAdmin')
    }
    // if (chkreSortAdmin && chkTLSAdmin) {
    //   appRolestr = '"reSortAdmin","tlsAdmin"'
    // }
    // else {
    //   if (chkreSortAdmin) {
    //     appRolestr = '"reSortAdmin"'
    //   }
    //   if (chkTLSAdmin) {
    //     appRolestr = '"tlsAdmin"'
    //   }

    // }

    //
    if (
      checkValidate(emailUser, messageEmailUser, 'Email') &&
      checkValidate(userName, messageUser, 'User name') &&
      checkValidate(passWord, messagePass, 'Pass word') &&
      checkValidate(fullName, messageFullName, 'Full name') &&
      checkValidate(userName, messageUser, 'DupClient') &&
      checkValidate2(chkreScale, currUserReScale < maxUserReScale, messageAppAccess, 'reScale') &&
      checkValidate2(chkreSort, currUserReSort < maxUserReSort, messageAppAccess2, 'reSort')
    ) {
      let user = [
        {
          user: inputUserNameRef.current.value,
          password: btoa(inputPasswordRef.current.value),
          email: inputEmailNameRef.current.value,
          permission: chkreScaleAdmin ? '["employee","admin"]' : '["employee"]',
          fullName: inputFullNameRef.current.value,
          appAccess: JSON.stringify( appAccessStr),
          appRole: JSON.stringify( appRolestr)
        },
      ];
      console.log('user add :', user);
      inputUserNameRef.current.value = '';
      inputPasswordRef.current.value = '';
      inputFullNameRef.current.value = '';
      inputEmailNameRef.current.value = '';
      setTimeout(() => {
        setIsAddcompleted(false);
      }, 3000);
      hubConnection.invoke('CrudUsers', 'post', user).then(() => {
        document.getElementById('appAccessMessage2').style.display = 'none';
        document.getElementById('appAccessMessage').style.display = 'none';
        document.getElementById('ckreScaleADD').checked = false;
        document.getElementById('ckreSortADD').checked = false;
        document.getElementById('ckreScaleADD_Admin').checked = false;
        document.getElementById('ckreSortADD_Admin').checked = false;
        document.getElementById('ckreScaleADD_Admin').disabled = true;
        document.getElementById('ckreSortADD_Admin').disabled = true;
        document.getElementById('ckTLSADD_Admin').checked = false;
        document.getElementById("modalboxAdd").click();
        setShowAlert(true);
      }).catch((err) => console.log(err));
      setShowAlert(false);
    }
  };

 
  const handleUpdateUser = () => {
    let dataUserName = document.getElementById('user-name-edit').value;
    //pass
    let dataPassWord = document.getElementById('pass-word-edit').value;
    let messagePassword = document.getElementById('password-edit-message');
    checkValidate(dataPassWord, messagePassword, 'Pass word');
    //email
    let dataEmail = document.getElementById('email-edit').value;
    let messageEmail = document.getElementById('email-edit-message');
    checkValidate(dataEmail, messageEmail, 'Email');
    //fullname
    let dataFullName = document.getElementById('full-name-edit').value;
    let messageFullName = document.getElementById('fullName-edit-message');
    //check app access
    let chkreScale = document.getElementById('ckreScaleEDIT').checked;
    let chkreSort = document.getElementById('ckreSortEDIT').checked;
    let chkPnD = document.getElementById('ckPnDEDIT').checked;

    let messageAppAccess3 = document.getElementById('appAccessMessage3');
    let messageAppAccess4 = document.getElementById('appAccessMessage4');
   
    let checkcurStateRescale = dataUser.filter(x => x.user === dataUserName)[0].appAccess.includes("reScale") ? 1 : 0
    let checkcurStateReSort = dataUser.filter(x => x.user === dataUserName)[0].appAccess.includes("reSort") ? 1 : 0
    let checkcurStatePnD = dataUser.filter(x => x.user === dataUserName)[0].appAccess.includes("PnD") ? 1 : 0

    let reSortOK = chkreSort && ((currUserReSort - checkcurStateReSort) < maxUserReSort)
    let reScaleOK = chkreScale && ((currUserReScale - checkcurStateRescale) < maxUserReScale)
    let PnD_OK = chkPnD && ((currUserPnD - checkcurStatePnD) < maxUserPnD)
    console.log('max PND',( currUserPnD - checkcurStatePnD))
    let appAccessStr = []
    if (reScaleOK)
    {
      appAccessStr.push('reScale')
    }
    if (reSortOK)
    {
      appAccessStr.push("reSort")
    }
    if(PnD_OK)
    {
      appAccessStr.push("PnD")
    }
   // console.log(appAccessStr)
    // if (reSortOK && reScaleOK) {
    //   appAccessStr = '["reScale", "reSort"]'
    // }
    // else {
    //   if (reSortOK) {
    //     appAccessStr = '["reSort"]'
    //   }
    //   if (reScaleOK) {
    //     appAccessStr = '["reScale"]'
    //   }

    // }

    let chkreScaleAdmin = document.getElementById('ckreScaleEDIT_Admin').checked;
    //checkValidate(chkreScale, messageAppAccess, 'Access'); 
    let chkreSortAdmin = document.getElementById('ckreSortEDIT_Admin').checked;
    let chkTLSAdmin = document.getElementById('ckTLSEDIT_Admin').checked;
    let chkPnDAdmin = document.getElementById('ckPnDEDIT_Admin').checked;
    
    let appRolestr = []
    if (chkreSortAdmin) {
      appRolestr.push('reSortAdmin')
    }
    if (chkTLSAdmin) {
      appRolestr.push('tlsAdmin')
    }
    if(chkPnDAdmin)
    {
      appRolestr.push('PnDAdmin')
    }
    // if (chkreSortAdmin && chkTLSAdmin) {
    //   appRolestr = '"reSortAdmin","tlsAdmin"'
    // }
    // else {
    //   if (chkreSortAdmin) {
    //     appRolestr = '"reSortAdmin"'
    //   }
    //   if (chkTLSAdmin) {
    //     appRolestr = '"tlsAdmin"'
    //   }

    // }

    if (
      checkValidate(dataPassWord, messagePassword, 'Pass word') &&
      checkValidate(dataEmail, messageEmail, 'Email') &&
      checkValidate(dataFullName, messageFullName, 'Full name') &&
      checkValidate2(chkreScale, (currUserReScale - checkcurStateRescale) < maxUserReScale, messageAppAccess3, 'reScale') &&
      checkValidate2(chkreSort, (currUserReSort - checkcurStateReSort) < maxUserReSort, messageAppAccess4, 'reSort') &&
      checkValidate2(chkPnD, (currUserPnD - checkcurStatePnD) < maxUserPnD, messageAppAccess4, 'PnD')
    ) {
      let user = [
        {
          user: dataUserName,
          password: btoa(dataPassWord),
          email: dataEmail,
          appAccess: JSON.stringify(appAccessStr),
          fullName: dataFullName,
          appRole: JSON.stringify(appRolestr),
          permission: chkreScaleAdmin ? '["employee","admin"]' : '["employee"]'

        },
      ];
      // document.getElementById('success-edit-message').innerText = 'Edit user completed !';
      hubConnection
        .invoke('CrudUsers', 'put', user)
        .then(() => {
          document.getElementById('appAccessMessage3').style.display = 'none';
          document.getElementById('appAccessMessage4').style.display = 'none';
          document.getElementById("modalboxUpdate").click();
          setShowAlertEdit(true);
        })
        .catch((err) => console.log(err));
        setShowAlertEdit(false);

    }
  };

  // delete
  const handleDeleteUser = (e) => {
    console.log(e.target.name);
    let dataDelete = [
      {
        user: e.target.name,
      },
    ];
    hubConnection
      .invoke('CrudUsers', 'delete', dataDelete)
      .then(() => setShowAlertDelete(true))
      .catch((err) => console.log(err));
    hubConnection.invoke('ReadUsers').then(() => document.getElementById("modalboxDelete").click()).catch((err) => console.log(err));
    setShowAlertDelete(false);
  };

  function handleChangeScaleAdd(e) {
    if (!e.target.checked) {

      document.getElementById('appAccessMessage').style.display = 'none';
      document.getElementById('ckreScaleADD_Admin').checked = false;
      document.getElementById('ckreScaleADD_Admin').disabled = true;
    }
    else {
      document.getElementById('ckreScaleADD_Admin').disabled = false;
    }
  }
  function handleChangePnDAdd(e) {
    if (!e.target.checked) {

      document.getElementById('appAccessMessage').style.display = 'none';
      document.getElementById('ckPnDADD_Admin').checked = false;
      document.getElementById('ckPnDADD_Admin').disabled = true;
    }
    else {
      document.getElementById('ckPnDADD_Admin').disabled = false;
    }
  }
  function handleChangeScaleEdit(e) {
    if (!e.target.checked) {

      document.getElementById('appAccessMessage3').style.display = 'none';
      document.getElementById('ckreScaleEDIT_Admin').checked = false;
      document.getElementById('ckreScaleEDIT_Admin').disabled = true;
    }
    else {
      document.getElementById('ckreScaleEDIT_Admin').disabled = false;
    }
  }
  //
  function handleChangePnDEdit(e) {
    if (!e.target.checked) {

      document.getElementById('appAccessMessage3').style.display = 'none';
      document.getElementById('ckPnDEDIT_Admin').checked = false;
      document.getElementById('ckPnDEDIT_Admin').disabled = true;
    }
    else {
      document.getElementById('ckPnDEDIT_Admin').disabled = false;
    }
  }
  function handleChangeSortEdit(e) {
    if (!e.target.checked) {

      document.getElementById('appAccessMessage4').style.display = 'none';
      document.getElementById('ckreSortEDIT_Admin').checked = false;
      document.getElementById('ckreSortEDIT_Admin').disabled = true;
    }
    else {
      document.getElementById('ckreSortEDIT_Admin').disabled = false;
    }
  }
  function handleChangeSortAdd(e) {
    if (!e.target.checked) {

      document.getElementById('appAccessMessage2').style.display = 'none';
      //ckreSortADD_Admin
      document.getElementById('ckreSortADD_Admin').checked = false;
      document.getElementById('ckreSortADD_Admin').disabled = true;

    }
    else {
      document.getElementById('ckreSortADD_Admin').disabled = false;
    }
  }
  function handleChangeUsername(e){
    document.getElementById('message-user').style.display='none'
  }


  const [showAlert, setShowAlert] = useState(false)
  const [showAlertEdit, setShowAlertEdit] = useState(false)
  const [showAlertDelete, setShowAlertDelete] = useState(false)


  return (
    <div className="pcoded-content">
      <HeaderContent title={'User And Roles'} breadcrumb={'user-and-roles'} link={'/users'} icon={'icofont-ui-user-group'} />
      <div className="pcoded-inner-content">
        <div className="main-body">
          <div className="page-wrapper">
            <div className="page-body">
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-header">

                      <button
                        type="button"
                        className="btn btn-success waves-effect waves-light"
                        data-bs-toggle="modal"
                        data-bs-target="#add-user"
                        onClick={() => handleSendDataToModalADD()}
                      >
                        <i className="icofont icofont-plus"></i>
                        Add User
                      </button>
                    
                      {showAlert && <Alert message="Add user successfully" />}
                      {showAlertEdit && <Alert message="Edit user successfully" />}
                      {showAlertDelete && <Alert message="Delete user successfully" />}
                      <div className="modal fade" id="add-user" tabIndex="-1" role="dialog">
                        <div className="modal-dialog" role="document">
                          <div className="modal-content">
                            <form>
                              <div className="modal-header">
                                <h4 className="modal-title text-center">Add New User</h4>
                                <div className="row">

                                </div>
                                <button id="modalboxAdd"
                                  type="button"
                                  className="btn-close"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                ></button>
                    
                              </div>
                              <div className="modal-body">
                                <div className="form-group row mb-3">
                                  <div className="col-sm-3">
                                    <label className=" col-form-label">Username</label>
                                  </div>
                                  <div className="col">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="name"
                                      id="name"
                                      placeholder="Enter username"
                                      ref={inputUserNameRef}
                                      onChange={handleChangeUsername}
                                    />
                                    <span id="message-user" className="messages"></span>
                                  </div>
                                </div>
                                <div className="form-group row mb-3">
                                  <div className="col-sm-3">
                                    <label className=" col-form-label">Password</label>
                                  </div>
                                  <div className="col">
                                    <input
                                      type="password"
                                      id="password"
                                      name="password"
                                      className="form-control col-sm-5"
                                      placeholder="Enter password"
                                      ref={inputPasswordRef}
                                    />
                                    <span id="passWord-message" className="messages"></span>
                                  </div>
                                </div>
                                <div className="form-group row mb-3">
                                  <div className="col-sm-3">
                                    <label className=" col-form-label">Name</label>
                                  </div>
                                  <div className="col">
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="full-name"
                                      name="full-name"
                                      placeholder="Enter name"
                                      ref={inputFullNameRef}
                                    />
                                    <span id="fullName-message" className="messages"></span>
                                  </div>
                                </div>
                                <div className="form-group row mb-3">
                                  <div className="col-sm-3">
                                    <label className=" col-form-label">Email address</label>
                                  </div>
                                  <div className="col">
                                    <input
                                      type="email"
                                      className="form-control"
                                      id="email"
                                      name="email"
                                      placeholder="@company.smug"
                                      ref={inputEmailNameRef}
                                    />
                                    <span id="email-message" className="messages"></span>
                                  </div>
                                </div>
                                <div className="form-group row mb-3">

                                  <table style={{ marginLeft: '11px' }}>
                                    <thead>
                                      <tr>
                                        <th>Application </th>
                                        <th>Basic</th>

                                        <th>Admin</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>reSort</td>
                                        <td><input id="ckreSortADD" type="checkbox" onChange={handleChangeSortAdd} defaultChecked={false}  ></input></td>

                                        <td><input id="ckreSortADD_Admin" type="checkbox" defaultChecked={false} disabled></input></td>
                                      </tr>
                                      <tr>
                                        <td>reScale</td>
                                        <td><input id="ckreScaleADD" type="checkbox" onChange={handleChangeScaleAdd} defaultChecked={false}  ></input></td>

                                        <td><input id="ckreScaleADD_Admin" type="checkbox" defaultChecked={false} disabled></input></td>
                                      </tr>
                                      <tr>
                                        <td>PnD</td>
                                        <td><input id="ckPnDADD" type="checkbox" onChange={handleChangePnDAdd} defaultChecked={false}  ></input></td>

                                        <td><input id="ckPnDADD_Admin" type="checkbox" defaultChecked={false} disabled></input></td>
                                      </tr>
                                      <tr>
                                        <td>TLS</td>
                                        <td><input id="ckTLSADD" type="checkbox" defaultChecked={true} disabled></input></td>

                                        <td><input id="ckTLSADD_Admin" type="checkbox" defaultChecked={false} ></input></td>
                                      </tr>
                                    </tbody>

                                  </table>

                                </div>
                                <div className="form-group row mb-3">
                                  <div className="col-sm-3">
                                    <label className=" col-form-label"></label>
                                  </div>
                                  <div className="col">
                                    {/* <div style={{ display: "inline-flex" }}>
                                      <input id="ckreScaleADD" type="checkbox" onChange={handleChangeScaleAdd}   ></input>
                                      <span style={{ marginLeft: "5px" }}> reScale </span>
                                    </div>
                                    <br></br>
                                    <div style={{ display: "inline-flex" }}>
                                      <input id="ckreSortADD" type="checkbox" onChange={handleChangeSortAdd}  ></input>
                                      <span style={{ marginLeft: "5px" }}> reSort </span>
                                    </div> */}
                                    <div><span id="appAccessMessage" className="messages"></span></div>
                                    <div><span id="appAccessMessage2" className="messages"></span></div>
                                  </div>
                                </div>
                              </div>
                              <div className="modal-footer">
                                <button type="button" className="btn btn-default waves-effect " data-bs-dismiss="modal">
                                  CANCEL
                                </button>
                                {/* button add */}
                                <button
                                  type="button"
                                  className="btn btn-primary waves-effect waves-light  "
                                  onClick={handleAddUser}
                                // data-bs-dismiss="modal"
                                >
                                  ADD
                                </button>
                            
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>

                      <div className="card-header-right" style={{ marginRight: "30px" }}>
                        <h6>reSort's users: {currUserReSort}/{maxUserReSort} - reScale's users: {currUserReScale}/{maxUserReScale}- PnD's users: {currUserPnD}/{maxUserPnD}</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-block">
                      <div className="card-block">
                        <div className="dt-responsive table-responsive">
                          <table id="order-table" className="table table-striped table-bordered nowrap">
                            <thead>
                              <tr>

                                <th>Username</th>
                                <th>Name</th>
                                <th>Email address</th>
                                <th>App access</th>
                                <th>App roles</th>
                                <th>Setting</th>
                              </tr>
                            </thead>
                            <tbody>
                              {dataUser?.map((user, index) => {
                                return (
                                  <tr key={index}>

                                    <td>{user.user}</td>
                                    <td>{user.fullName}</td>
                                    <td>{user.email}</td>
                                    {/* <td>{user.appAccess.replaceAll("\"", "").replace("[", "").replace("]", "")}{user.permission.includes("admin") ? ",reScaleAdmin" : ""}{user.appRole.length > 0 ? ',' : ''}{user.appRole.replaceAll("\"", "")}</td>
                                     */}
                                    <td>{JSON.parse(user.appAccess).map(x=>x +', ')}</td>
                                    <td>{user.permission.includes("admin") ? "reScaleAdmin " : ""}{user.appRole && JSON.parse(user.appRole).map(x=>x +', ')}</td>
                                    <td key={index} className="button-page">
                                      <button
                                        type="button"
                                        title="Edit User"
                                        className="btn btn-default btn-outline-default waves-effect md-trigger"
                                        data-bs-toggle="modal"
                                        data-bs-target={`#edit-user`}
                                        onClick={() => handleSendDataToModal(index)}
                                      >
                                        <i className="icofont icofont-pen-alt-4"></i>
                                      </button>


                                      <button
                                        type="button"
                                        title="Delete User"
                                        className="btn btn-default   btn-outline-danger waves-effect md-trigger"
                                        data-bs-toggle="modal"
                                        data-bs-target={`#delete-user${index}`}
                                      >
                                        <i className="icofont icofont-trash"></i>
                                      </button>
                                      <div
                                        className="modal fade"
                                        id={`delete-user${index}`}
                                        tabIndex="-1"
                                        role="dialog"
                                      >
                                        <div className="modal-dialog" role="document">
                                          <div className="modal-content">
                                            <div className="modal-header">
                                              {/* header : title , ... */}
                                              <h4 className="modal-title text-center">Delete user</h4>
                                              <button id="modalboxDelete"
                                                type="button"
                                                className="btn-close"
                                                data-bs-dismiss="modal"
                                                aria-label="Close"
                                              ></button>
                                            </div>
                                            <div className="modal-body">
                                              <h5>
                                                Do you want to delete user{' '}
                                                <span className="text-primary">{user.user}</span> ?
                                              </h5>
                                            </div>
                                            <div className="modal-footer">
                                              <button
                                                type="button"
                                                className="btn btn-default waves-effect "
                                                data-bs-dismiss="modal"
                                              >
                                                NO
                                              </button>
                                              <button
                                                type="button"
                                                name={user.user}
                                                onClick={(e) => handleDeleteUser(e)}
                                                className="btn btn-primary waves-effect waves-light "
                                                data-bs-dismiss="modal"
                                              >
                                                YES
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id={`edit-user`} tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title text-center">Edit User</h4>
              <span id="success-edit-message" className="messages"></span>
              <button id="modalboxUpdate" type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="form-group row">
                <div className="col-sm-3">
                  <label className=" col-form-label">Username</label>
                </div>
                <div className="col">
                  <input type="text" id="user-name-edit" name="user-update" className="form-control" readOnly />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-3">
                  <label className=" col-form-label">Password</label>
                </div>
                <div className="col">
                  <button
                    type="button"
                    id="btn-show-pass"
                    className="btn btn-primary btn-outline-primary waves-effect "
                    onClick={handleChangePassword}
                  >
                    Change password
                  </button>

                  <input
                    style={{ display: 'none' }}
                    type="password"
                    name="password-user-update"
                    className="form-control col-sm-5"
                    id="pass-word-edit"
                  />
                  <span id="password-edit-message" className="messages"></span>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-3">
                  <label className=" col-form-label">Name</label>
                </div>
                <div className="col">
                  <input type="text" name="fullName-user-update" className="form-control" id="full-name-edit" />
                  <span id="fullName-edit-message" className="messages"></span>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-3">
                  <label className=" col-form-label">Email address</label>
                </div>
                <div className="col">
                  <input
                    type="text"
                    name="email-user-update"
                    className="form-control"
                    placeholder="@company.smug"
                    id="email-edit"
                  />
                  <span id="email-edit-message" className="messages"></span>
                </div>
              </div>
              <div className="form-group row mb-3">

                <table style={{ marginLeft: '11px' }}>
                  <thead>
                    <tr>
                      <th>Application </th>
                      <th>Basic</th>

                      <th>Admin</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>reSort</td>
                      <td><input id="ckreSortEDIT" type="checkbox" onChange={handleChangeSortEdit} defaultChecked={false}  ></input></td>

                      <td><input id="ckreSortEDIT_Admin" type="checkbox" defaultChecked={false} ></input></td>
                    </tr>
                    <tr>
                      <td>reScale</td>
                      <td><input id="ckreScaleEDIT" type="checkbox" onChange={handleChangeScaleEdit} defaultChecked={false}  ></input></td>

                      <td><input id="ckreScaleEDIT_Admin" type="checkbox" defaultChecked={false} ></input></td>
                    </tr>
                    <tr>
                      <td>PnD</td>
                      <td><input id="ckPnDEDIT" type="checkbox" onChange={handleChangePnDEdit} defaultChecked={false}  ></input></td>

                      <td><input id="ckPnDEDIT_Admin" type="checkbox" defaultChecked={false} ></input></td>
                    </tr>
                    <tr>
                      <td>TLS</td>
                      <td><input id="ckTLSEDIT" type="checkbox" defaultChecked={true} disabled></input></td>

                      <td><input id="ckTLSEDIT_Admin" type="checkbox" defaultChecked={false} ></input></td>
                    </tr>
                  </tbody>

                </table>

              </div>
              <div className="form-group row">
                {/* <div className="col-sm-3">
                  <label className=" col-form-label">Access</label>

                </div> */}
                <div className="col-sm-6">
                  {/* <div>
                    <input id="ckreScale" type="checkbox" onChange={handleChangeScale}   ></input>
                    <span> reScale </span>
                  </div>
                  <div>
                    <input id="ckreSort" type="checkbox" onChange={handleChangeSort} ></input>
                    <span> reSort </span>
                  </div> */}
                  <div><span id="appAccessMessage3" className="messages"></span></div>
                  <div><span id="appAccessMessage4" className="messages"></span></div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-default waves-effect " data-bs-dismiss="modal">
                  CANCEL
                </button>
                <button
                  type="button"
                  id="btn-close-edit"
                  className="btn btn-primary waves-effect waves-light "
                  onClick={handleUpdateUser}
                >
                  SAVE
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>
  );
}

export default UserManager;
