import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useLayoutEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { HeaderContent } from '../../component/page';
import { useStore, actions, handles } from '../../store';
import userEvent from '@testing-library/user-event';
import Alert from '../../component/Alert';


function Company() {
  //store
  const { state, dispatch } = useStore();
  const { hubConnection, isCheckScript } = state;
  const { toggleCheckScript } = actions;
  const { runExistingScripts } = handles;
  // use ref
  const inputUserNameRef = useRef(null);
  const messageUserNameRef = useRef(null);
  const inputPasswordRef = useRef(null);
  const messagePasswordRef = useRef(null);
  const inputFullNameRef = useRef(null);
  const messageFullNameRef = useRef(null);
  const inputEmailNameRef = useRef(null);
  const messageEmailNameRef = useRef(null);
  // use ref 2
  const valueUserNameRef = useRef(null);
  const valuePasswordRef = useRef(null);
  const valueFullNameRef = useRef(null);
  const valueEmailUserRef = useRef(null);
  // useState
  const [dataCompany, setDataCompany] = useState();

  const [isValidate, setIsValidate] = useState(false);
  const [isAddcompleted, setIsAddcompleted] = useState(false);
  const [dataLicense, setDataLicense] = useState();
  const [maxUserReScale, setmaxUserReScale] = useState();
  const [maxUserReSort, setmaxUserReSort] = useState();


  const [showAlert, setShowAlert] = useState(false)
  const [showAlertEdit, setShowAlertEdit] = useState(false)
  const [showAlertDelete, setShowAlertDelete] = useState(false)

  let navigate = useNavigate();
  const userLogin = JSON.parse(Cookies.get('loggedInUser'));
  //useEffect
  useLayoutEffect(() => {
    runExistingScripts(dispatch, toggleCheckScript, isCheckScript, true);
    // eslint-disable-next-line
  }, []);
  // onChange Connect
  useEffect(() => {
    if (hubConnection) {
      hubConnection.invoke('ReadEmailUserByUserName', userLogin.user).catch((err) => console.log(err));

      hubConnection.on('SendReadEmailUserByUserName', (param) => {

        if (param.appRole === null || !param.appRole.includes("tlsAdmin")) {
          navigate("/dashboard");
        }
      });
      //invoke
      hubConnection.invoke('ReadCompanies').catch((err) => console.log(err));
      hubConnection.on('SendReadCompanies', (param) => {
        //console.log('SendReadCompanies : ', param);
        setDataCompany(param)
      });
      hubConnection.on('SendCrudCompany', (userResponse) => {
        // console.log(userResponse);
        if (userResponse.isSuccessStatusCode) {
          if (userResponse.put) {
            console.log('update');
            setIsAddcompleted(true);
          } else if (userResponse.post) {
            console.log('add');
            setIsAddcompleted(true);
          } else if (userResponse.delete) {
            console.log('delete');
          }
          hubConnection.invoke('ReadCompanies').catch((err) => console.log(err));
        }
      });


      hubConnection.invoke('ReadLicense').catch((err) => console.log("lic error: " + err));
      hubConnection.on('SendReadLicense', (param) => {
        // console.log('param key:', param);
        const dataLicenseKey = JSON.parse(param);
        // console.log('license key:', );
        setmaxUserReScale(dataLicenseKey.Applications.filter((auto) => auto.Application.includes("reScale"))[0].User)
        setmaxUserReSort(dataLicenseKey.Applications.filter((auto) => auto.Application.includes("reSort"))[0].User)
        setDataLicense(dataLicenseKey);
      });
    }

  }, [hubConnection]);
  // console.log(dataUser);
  const handleChangePassword = () => {
    document.getElementById('pass-word-edit').style.display = 'block';
    document.getElementById('btn-show-pass').style.display = 'none';
  };

  // add user
  // console.log(dataUser);
  const checkValidate = (elementInput, elementMessage, message) => {
    elementMessage.style.display = 'none';
    elementMessage.style.color = 'red';
    elementMessage.style.fontSize = '13px';
    elementMessage.style.paddingLeft = '5px';
    if (elementInput === '') {
      elementMessage.style.display = 'block';
      elementMessage.innerText = `${message} can't be blank !`;
      return false;
    }
    if (message === 'CompanyName') {
      var checkExist = dataCompany.filter(x => x.companyName.toUpperCase() === elementInput.toUpperCase()).length > 0 ? false : true
      if (checkExist === false) {
        elementMessage.style.display = 'block';
        elementMessage.innerText = `${elementInput} already exists !`;
        return false;
      }
      return true;

    }
  };
  const handleSendDataToModal = (index) => {
    let dataUserTarget = dataCompany.at(index);
    document.getElementById('user-name-edit').value = dataUserTarget.companyName;

    document.getElementById('ckreScale').checked = String(dataUserTarget.appRole).includes("reScale");
    document.getElementById('ckreSort').checked = String(dataUserTarget.appRole).includes("reSort");
  };
  // add company
  const handleAddUser = () => {
    let userName = inputUserNameRef.current.value;
    let messageUser = document.getElementById('message-user');

    //console.log(checkValidate(userName, messageUser, 'CompanyName'));
    //
    let chkreScale = document.getElementById('ckreScaleADD').checked;
    let chkreSort = document.getElementById('ckreSortADD').checked;
    let appAccessStr = chkreScale ? "reScale," : "";
    //appAccessStr += appAccessStr === "" ? "":"," modalboxAdd
    if (chkreSort) {
      appAccessStr += "reSort"
    }
    else {
      appAccessStr = appAccessStr.replace(",", "")
    }


    if (

      checkValidate(userName, messageUser, 'CompanyName')

    ) {
      let compa =
      {
        companyName: inputUserNameRef.current.value,
        appRole: appAccessStr
      };
      //console.log('company add :', compa);
      inputUserNameRef.current.value = '';

      setTimeout(() => {
        setIsAddcompleted(false);
      }, 3000);
      hubConnection.invoke('CrudCompany', 'post', compa)
        .then(() => { document.getElementById("modalboxAdd").click();setShowAlert(true) })
        .catch((err) => console.log(err));

        setShowAlert(false)
    }
  };

  // update
  const handleUpdateUser = () => {
    let dataUserName = document.getElementById('user-name-edit').value;
    //pass

    //check app access
    let chkreScale = document.getElementById('ckreScale').checked;
    let chkreSort = document.getElementById('ckreSort').checked;


    let appAccessStr = chkreScale ? "reScale," : "";
    //appAccessStr += appAccessStr === "" ? "":","
    if (chkreSort) {
      appAccessStr += "reSort"
    }
    else {
      appAccessStr = appAccessStr.replace(",", "")
    }

    //
    {
      let compa =
      {
        companyName: dataUserName,

        appRole: appAccessStr

      };

      // document.getElementById('success-edit-message').innerText = 'Edit user completed !';
      hubConnection
        .invoke('CrudCompany', 'put', compa)
        .then(() => { document.getElementById("modalboxEdit").click(); setShowAlertEdit(true) })
        .catch((err) => console.log(err));

      setShowAlertEdit(false)
    }
  };

  // delete
  const handleDeleteUser = (e) => {
    console.log("begin delete");
    console.log(e.target.name);
    let dataDelete =
    {
      companyName: e.target.name,
    };

    hubConnection
      .invoke('CrudCompany', 'delete', dataDelete)
      .then(() => setShowAlertDelete(true))
      .catch((err) => console.log(err));

    setShowAlertDelete(false)
    hubConnection.invoke('CrudCompany').then(() => document.getElementById("modalboxDelete").click()).catch((err) => console.log(err));
  };

  const [checkedScale, setCheckedScale] = useState(false);
  function handleChangeScale(e) {
    setCheckedScale(e.target.checked);
  }

  const [checkedSort, setCheckedSort] = useState(false);
  function handleChangeSort(e) {
    setCheckedSort(e.target.checked);
  }

  const handleSendDataToModalADD = () => {
    document.getElementById('ckreSortADD').checked = false;
    document.getElementById('ckreScaleADD').checked = false;
    document.getElementById('message-user').style.display='none';
  }
  function handleChangeName(e){
    document.getElementById('message-user').style.display='none'
  }

  return (
    <div className="pcoded-content">
      <HeaderContent title={'Customers'} breadcrumb={'customers'} link={'/Company'} icon={'icofont-contacts'} />
      <div className="pcoded-inner-content">
        <div className="main-body">
          <div className="page-wrapper">
            <div className="page-body">
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-header">

                      <button
                        type="button"
                        className="btn btn-success waves-effect waves-light"
                        data-bs-toggle="modal"
                        data-bs-target="#add-user"
                        onClick={() => handleSendDataToModalADD()}
                      >
                        <i className="icofont icofont-plus"></i>
                        Add Customer
                      </button>
                      {showAlert && <Alert message="Add customer successfully" />}
                      {showAlertEdit && <Alert message="Edit customer successfully" />}
                      {showAlertDelete && <Alert message="Delete customer successfully" />}
                      <div className="modal fade" id="add-user" tabIndex="-1" role="dialog">
                        <div className="modal-dialog" role="document">
                          <div className="modal-content">
                            <form>
                              <div className="modal-header">
                                <h4 className="modal-title text-center">Add New Customer</h4>

                                <button
                                  id="modalboxAdd"
                                  type="button"
                                  className="btn-close"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                ></button>
                              </div>
                              <div className="modal-body">
                                <div className="form-group row mb-3">
                                  <div className="col-sm-3">
                                    <label className=" col-form-label">Customer name</label>
                                  </div>
                                  <div className="col">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="name"
                                      id="name"
                                      placeholder="Enter customer name"
                                      ref={inputUserNameRef}
                                      onChange={handleChangeName}
                                    />
                                    <span id="message-user" className="messages"></span>
                                  </div>
                                </div>
                                <div className="form-group row mb-3">
                                  <div className="col-sm-3">
                                    <label className=" col-form-label">App access</label>
                                  </div>
                                  <div className="col">
                                    <div style={{ display: "inline-flex" }}>
                                      <input id="ckreScaleADD" type="checkbox" onChange={handleChangeScale} innerText="reScale"  ></input>
                                      <span style={{ marginLeft: "5px" }}> reScale </span>
                                    </div>
                                    <br></br>
                                    <div style={{ display: "inline-flex" }}>
                                      <input id="ckreSortADD" type="checkbox" onChange={handleChangeSort} innerText="reSort" ></input>
                                      <span style={{ marginLeft: "5px" }}> reSort </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="modal-footer">
                                <button type="button" className="btn btn-default waves-effect " data-bs-dismiss="modal">
                                  CANCEL
                                </button>
                                {/* button add */}
                                <button
                                  type="button"
                                  className="btn btn-primary waves-effect waves-light  "
                                  onClick={handleAddUser}
                                // data-bs-dismiss="modal"
                                >
                                  ADD
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-block">
                      <div className="card-block">
                        <div className="dt-responsive table-responsive">
                          <table id="order-table" className="table table-striped table-bordered nowrap">
                            <thead>
                              <tr>

                                <th>Customer Name</th>
                                <th>Applications</th>
                                <th>Setting</th>
                              </tr>
                            </thead>
                            <tbody>
                              {dataCompany?.map((user, index) => {
                                return (
                                  <tr key={index}>

                                    <td>{user.companyName}</td>
                                    <td>{user.appRole.replaceAll("\"", "").replace("\[", "").replace("\]", "")}</td>
                                    <td key={index} className="button-page">
                                      <button
                                        type="button"
                                        className="btn btn-default btn-outline-default waves-effect md-trigger"
                                        data-bs-toggle="modal"
                                        data-bs-target={`#edit-user`}
                                        onClick={() => handleSendDataToModal(index)}
                                        title="Edit Customer"
                                      >
                                        <i className="icofont icofont-pen-alt-4"></i>
                                      </button>

                                      <button
                                        type="button"
                                        className="btn btn-default   btn-outline-danger waves-effect md-trigger"
                                        data-bs-toggle="modal"
                                        data-bs-target={`#delete-user${index}`}
                                        title="Delete Customer"
                                      >
                                        <i className="icofont icofont-trash"></i>
                                      </button>
                                      <div
                                        className="modal fade"
                                        id={`delete-user${index}`}
                                        tabIndex="-1"
                                        role="dialog"
                                      >
                                        <div className="modal-dialog" role="document">
                                          <div className="modal-content">
                                            <div className="modal-header">
                                              {/* header : title , ... */}
                                              <h4 className="modal-title text-center">Delete Customer</h4>
                                              <button
                                                id="modalBoxDelete"
                                                type="button"
                                                className="btn-close"
                                                data-bs-dismiss="modal"
                                                aria-label="Close"
                                              ></button>
                                            </div>
                                            <div className="modal-body">
                                              <h5>
                                                Do you want to delete customer {' '}
                                                <span className="text-primary">{user.companyName}</span> ?
                                              </h5>
                                            </div>
                                            <div className="modal-footer">
                                              <button
                                                type="button"
                                                className="btn btn-default waves-effect "
                                                data-bs-dismiss="modal"
                                              >
                                                NO
                                              </button>
                                              <button
                                                type="button"
                                                name={user.companyName}
                                                onClick={(e) => handleDeleteUser(e)}
                                                className="btn btn-primary waves-effect waves-light "
                                                data-bs-dismiss="modal"
                                              >
                                                YES
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id={`edit-user`} tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title text-center">Edit Customer</h4>
              <span id="success-edit-message" className="messages"></span>
              <button id="modalboxEdit" type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="form-group row">
                <div className="col-sm-3">
                  <label className=" col-form-label">Customer name</label>
                </div>
                <div className="col">
                  <input type="text" id="user-name-edit" name="user-update" className="form-control" readOnly />
                </div>
              </div>



              <div className="form-group row">
                <div className="col-sm-3">
                  <label className=" col-form-label">Applications</label>
                </div>
                <div className="col-sm-6">
                  <div>
                    <input id="ckreScale" type="checkbox" onChange={handleChangeScale} innerText="reScale"  ></input>
                    <span> reScale </span>
                  </div>
                  <div>
                    <input id="ckreSort" type="checkbox" onChange={handleChangeSort} innerText="reSort" ></input>
                    <span> reSort </span>
                  </div>


                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-default waves-effect " data-bs-dismiss="modal">
                CANCEL
              </button>
              <button
                type="button"
                id="btn-close-edit"
                className="btn btn-primary waves-effect waves-light "
                onClick={handleUpdateUser}
              >
                SAVE
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Company;
