import ReactDOM from 'react-dom';
import { useEffect, useRef, useState } from 'react';
const ModalCustomTimes = ({ idModal, title = 'Title Modal', setInnerCustom, setTimeFrom, setTimeTo, handleValueChart }) => {
  const [timeToModal, setTimeToModal] = useState(new Date());
  const [timeFromModal, setTimeFromModal] = useState(new Date());
  const [defaultTo, setDefaultTo] = useState(
  );
  const [defaultFrom, setDefaultFrom] = useState();
  // useRef
  const valueFromDateRef = useRef(null);
  const valueToDateRef = useRef(null);
  // format date time to yyyy-MM-DD
  const formatDate = (d) => {
    return d.getFullYear().toString() + "-" + ((d.getMonth() + 1).toString().length == 2 ? (d.getMonth() + 1).toString() : "0" + (d.getMonth() + 1).toString())
      + "-" + (d.getDate().toString().length == 2 ? d.getDate().toString() : "0" + d.getDate().toString());
  }
  // set default time from/to
  useEffect(() => {
    setDefaultTo(formatDate(new Date));
    setDefaultFrom(formatDate(new Date));
  }, [])
  // get 00:00 of day
  const getStartOfDay = (dateString) => {
    const date = new Date(dateString);
    date.setHours(0, 0, 0, 0);
    return date.getTime();
  };
  // get 23:00 of day
  const getEndOfDay = (dateString) => {
    const date = new Date(dateString);
    date.setHours(23, 59, 59, 999);
    return date.getTime();
  };
  // next btn modal
  // const handleCheckDate = () => {
  //   // hien thi html
  //   setInnerCustom(`${valueFromDateRef.current.value} - ${valueToDateRef.current.value}`);
  //   // set time from & to
  //   setTimeFrom(getStartOfDay(valueFromDateRef.current.value));
  //   setTimeTo(getEndOfDay(valueToDateRef.current.value));
  //   // sao luu lai ngay gio
  //   setTimeFromModal(getStartOfDay(valueFromDateRef.current.value));
  //   setTimeToModal(getEndOfDay(valueToDateRef.current.value));
  //   // 
  //   handleValueChart('custom-date-chart');
  // };
  const handleCheckDate = () => {
    const timeFrom = getStartOfDay(valueFromDateRef.current.value)
    const timeTo = getEndOfDay(valueToDateRef.current.value)
    setInnerCustom(`${valueFromDateRef.current.value} - ${valueToDateRef.current.value}`);
    setTimeFrom(timeFrom);
    setTimeTo(timeTo);
    setTimeFromModal(getStartOfDay(valueFromDateRef.current.value));
    setTimeToModal(getEndOfDay(valueToDateRef.current.value));
    handleValueChart('custom-date-chart', timeFrom, timeTo);
  };
  // cancel modal (redo to time check next)
  const handleCancelModal = () => {
    valueToDateRef.current.value = formatDate(new Date(timeToModal));
    valueFromDateRef.current.value = formatDate(new Date(timeFromModal));
  }
  return ReactDOM.createPortal(
    <div className="modal fade" id={idModal} tabIndex="-1" role="dialog">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title text-center">{title}</h4>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleCancelModal}></button>
          </div>
          <div className="modal-body text-left">
            <div className="row">
              <label className="w-12 m-t-5">From</label>
              <input type="date" className="col-sm form-control" ref={valueFromDateRef} defaultValue={defaultFrom} />
              <label className="w-12 m-t-5 p-l-22">To</label>
              <input type="date" className="col-sm form-control" ref={valueToDateRef} defaultValue={defaultTo} />
            </div>
          </div>
          <div className="modal-footer justify-s-between">
            <button type="button" className="btn btn-default btn-round waves-effect" data-bs-dismiss="modal" onClick={handleCancelModal}>
              CANCEL
            </button>
            <button
              type="button"
              className="btn btn-primary btn-round waves-effect waves-light"
              data-bs-dismiss="modal"
              onClick={handleCheckDate}
            >
              NEXT
            </button>
          </div>
        </div>
      </div>
    </div>,
    document.body,
  );
};

export default ModalCustomTimes;
