const OpenModal = ({ children, idModal, title = 'Open Modal', colorText, type, classStyle }) => {
  return (
    <button
      type="button"
      style={{ color: colorText }}
      className={`${type === 'Dashboard'
        ? `btn-round btn ${classStyle}`
        : `btn btn-default btn-createJob btn-outline-default waves-effect md-trigger ${colorText}`
        } `}
      name={idModal}
      data-bs-toggle="modal"
      data-bs-target={`#${idModal}`}
      title={title}
    >
      {children}
    </button>
  );
};

export default OpenModal;
