import { useLayoutEffect, useState, useRef } from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { HubConnectionBuilder } from '@microsoft/signalr';
import Cookies from 'js-cookie';

import config from './config';
import { useStore, actions, handles } from './store';
import { privateRoutes, publicRoutes } from './routes/routes';
function App() {
  //store
  const { dispatch } = useStore();
  const { toggleHubConnection } = actions;
  const { isLoggedIn } = handles;
  const location = useLocation();
  //useRef
  const timeoutIdRef = useRef(null);

  //useState
  const [refresh, setRefresh] = useState(false);

  //useEffect, useLayoutEffect
  //Connect Hub
  useLayoutEffect(() => {
    const createHubConnection = async () => {
      const hubConnection = new HubConnectionBuilder().withUrl(process.env.REACT_APP_BASE_URL+'notificationHub').build();
      // const hubConnection = new HubConnectionBuilder().withUrl('http://localhost:8888/notificationHub').build();
      try {
        await hubConnection.start();
      } catch (e) {
        console.log(e);
        // console.log(refresh);
      }
      dispatch(toggleHubConnection(hubConnection));
    };
    createHubConnection();
    // eslint-disable-next-line
  }, [dispatch, toggleHubConnection]);

  useLayoutEffect(() => {
    if (location.pathname !== config.routes.login) {
      Cookies.remove('lastVisitedPath');
      Cookies.set('lastVisitedPath', window.location.href);
    

    if (isLoggedIn) {
      const expirationTime = 1 / 12;
      const millisecondsPerDay = 24 * 60 * 60 * 1000;
      Cookies.set('loggedInUser_expires', Date.now() + expirationTime * millisecondsPerDay, {
        expires: expirationTime,
      });
      Cookies.set('loggedInUser', JSON.stringify(JSON.parse(Cookies.get('loggedInUser'))), {
        expires: expirationTime,
      });
    }

    const cookieExpirationDate = Cookies.get('loggedInUser_expires');
    const currentTime = new Date().getTime();
    const timeUntilTimestamp = cookieExpirationDate - currentTime;
    if (timeUntilTimestamp > 0) {
      timeoutIdRef.current = setTimeout(() => {
        setRefresh((prev) => !prev);
      }, timeUntilTimestamp);
    }

    const route = privateRoutes.find((route) => route.path === location.pathname);
    if (route && route.title) {
      document.title = route.title;
    }
  }
    return () => {
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
    }
  };
}, [location, isLoggedIn]);
let dateDemo = Date.now();
//console.log('timesamp APP: ', dateDemo);
return (
  <Routes>
    {publicRoutes.map((route, index) => (
      <Route
        key={index}
        path={route.path}
        element={
          isLoggedIn() ? (
            <Navigate to={config.routes.dashboard} />
          ) : (
            <route.layout>
              <route.component />
            </route.layout>
          )
        }
      />
    ))}

    {privateRoutes.map((route, index) => (
      <Route
        key={publicRoutes.length + index}
        path={route.path}
        element={
          isLoggedIn() ? (
            <route.layout>
              <route.component />
            </route.layout>
          ) : (
            <Navigate to={config.routes.login} />
          )
        }
      />
    ))}
  </Routes>
);
}

export default App;
