import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
//
import reportWebVitals from './reportWebVitals';
import { HashRouter as Router } from 'react-router-dom';
import { StoreProvider } from './store';
import GlobalStyles from './component/GlobalStyles';
//const GlobalStyles = React.lazy(() => import('./component/GlobalStyles'));
//const App = React.lazy(() => import('./App'));

const root = ReactDOM.createRoot(document.getElementById('pcoded'));
root.render(
  <GlobalStyles>
    <StoreProvider>
      <Router>
      
        <App />

      </Router>
    </StoreProvider>
  </GlobalStyles>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
