import classNames from 'classnames/bind';
import styles from './HeaderContent.module.scss';
import { NavLink } from 'react-router-dom';

const cx = classNames.bind(styles);
function HeaderContent({ title, icon, breadcrumb ,link}) {
  return (
    <div className="page-header card">
      <div className="row align-items-end">
        <div className="col-lg-8">
          <div className="page-header-title">
            <i className={cx('feather', 'icofont', 'bg-c-blue', icon)}></i>
            <div className="d-inline">
              <h5>{title}</h5>
              {/* <span>lorem ipsum dolor sit amet, consectetur adipisicing elit</span> */}
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="page-header-breadcrumb">
            <ul className=" breadcrumb breadcrumb-title breadcrumb-padding">
              {/* <li className="breadcrumb-item">
                <NavLink to="/dashboard">
                  <i className="feather icon-home"></i>
                </NavLink>
              </li>
              <li className="breadcrumb-item">
                  <NavLink to={link}>{breadcrumb}</NavLink>
               
              </li> */}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeaderContent;
