import Cookies from 'js-cookie';
import { NavLink, useLocation } from 'react-router-dom';
import './headerCustom.css';
import config from '../../../config';
import { useState, useEffect, lazy, useLayoutEffect, useRef } from 'react';
import { useStore, actions, handles } from '../../../store';
import Tooltip from './Tooltip';
function Header() {
  const userLogin = JSON.parse(Cookies.get('loggedInUser'));

  const { state, dispatch } = useStore();
  const { hubConnection, isCheckScript } = state;
  const [userData, setuserData] = useState({});
  const [isOpen, setIsOpen] = useState(false); // State for chatbox visibility
  const chatboxRef = useRef(null); // Reference to the chatbox element

  const handleClick = () => {
    if (isOpen == false) {
      document.getElementById('sidebar').hidden = false;
    } else {
      document.getElementById('sidebar').hidden = true;
    }
    setIsOpen(!isOpen); // Toggle open state on click
  };

  let location = useLocation();

  useEffect(() => {
    if (hubConnection) {
      //invoke
      hubConnection.invoke('ReadEmailUserByUserName', userLogin.user).catch((err) => console.log(err));

      hubConnection.on('SendReadEmailUserByUserName', (param) => {
        setuserData(param);
        console.log('user info', param);
        //  console.log(!param.appRole.includes("tlsAdmin"))
      });
    }
  }, [hubConnection]);
  const handleLogout = async () => {
    Cookies.remove('loggedInUser');
    Cookies.remove('loggedInUser_expires');
  };

  const handleSendDataToModal = (e) => {
    let _state = e.target.value;
    let componentID = e.target.name;
    //console.log(_state);

    document.getElementById(componentID).hidden = !_state;
    //check resort
    if (document.getElementById('ckSortWALL')) {
      if (
        document.getElementById('ckSort1').checked &&
        document.getElementById('ckSort2').checked &&
        document.getElementById('ckSort3').checked &&
        document.getElementById('ckSort4').checked
      ) {
        // id="ckSortWALL"
        document.getElementById('ckSortWALL').checked = true;
        document.getElementById('allreSort1').hidden = false;
        document.getElementById('allreSort2').hidden = false;
      } else if (
        document.getElementById('ckSort1').checked ||
        document.getElementById('ckSort2').checked ||
        document.getElementById('ckSort3').checked ||
        document.getElementById('ckSort4').checked
      ) {
        //document.getElementById("ckSort4").checked =true;
        document.getElementById('allreSort1').hidden = false;
        document.getElementById('allreSort2').hidden = false;
      } else {
        document.getElementById('ckSortWALL').checked = false;
        document.getElementById('allreSort1').hidden = true;
        document.getElementById('allreSort2').hidden = true;
      }
    }

    //check rescale
    if (document.getElementById('ckScaleWALL')) {
      if (
        document.getElementById('ckTrack1').checked &&
        document.getElementById('ckTrack2').checked &&
        document.getElementById('ckTrack21').checked &&
        document.getElementById('ckTrack22').checked &&
        document.getElementById('ckTrack3').checked
      ) {
        // id="ckSortWALL"
        document.getElementById('ckScaleWALL').checked = true;
        document.getElementById('allreScale1').hidden = false;
        document.getElementById('allreScale2').hidden = false;
      } else if (
        document.getElementById('ckTrack1').checked ||
        document.getElementById('ckTrack2').checked ||
        document.getElementById('ckTrack21').checked ||
        document.getElementById('ckTrack22').checked ||
        document.getElementById('ckTrack3').checked
      ) {
        document.getElementById('allreScale1').hidden = false;
        document.getElementById('allreScale2').hidden = false;
      } else {
        document.getElementById('ckScaleWALL').checked = false;
        document.getElementById('allreScale1').hidden = true;
        document.getElementById('allreScale2').hidden = true;
      }
    }

    //check PnD
    if (document.getElementById('ckPnDALL')) {
      if (
        document.getElementById('ckPnD1').checked &&
        document.getElementById('ckPnD2').checked &&
        document.getElementById('ckPnD22').checked &&
        document.getElementById('ckPnD21').checked
      ) {
        // id="ckSortWALL"
        document.getElementById('ckPnDALL').checked = true;
        document.getElementById('allPnD1').hidden = false;
        document.getElementById('allPnD2').hidden = false;
      } else if (
        document.getElementById('ckPnD1').checked ||
        document.getElementById('ckPnD2').checked ||
        document.getElementById('ckPnD22').checked ||
        document.getElementById('ckPnD21').checked
      ) {
        document.getElementById('allPnD1').hidden = false;
        document.getElementById('allPnD2').hidden = false;
      } else {
        document.getElementById('ckPnDALL').checked = false;
        document.getElementById('allPnD1').hidden = true;
        document.getElementById('allPnD2').hidden = true;
      }
    }
  };
  const handleSendDataToModalResortAll = (e) => {
    let _state = e.target.value;
    let componentID = e.target.name;
    // console.log(_state);

    document.getElementById('ckSort1').checked = _state;
    handleSendDataToModal({
      target: {
        name: document.getElementById('ckSort1').name,
        value: document.getElementById('ckSort1').checked,
      },
    });
    document.getElementById('ckSort2').checked = _state;
    handleSendDataToModal({
      target: {
        name: document.getElementById('ckSort2').name,
        value: document.getElementById('ckSort2').checked,
      },
    });
    document.getElementById('ckSort3').checked = _state;
    handleSendDataToModal({
      target: {
        name: document.getElementById('ckSort3').name,
        value: document.getElementById('ckSort3').checked,
      },
    });
    document.getElementById('ckSort4').checked = _state;
    handleSendDataToModal({
      target: {
        name: document.getElementById('ckSort4').name,
        value: document.getElementById('ckSort4').checked,
      },
    });
    //id='allreScale1'
    document.getElementById('allreSort1').hidden = !_state;
    document.getElementById('allreSort2').hidden = !_state;
  };
  const handleSendDataToModalreScaletAll = (e) => {
    let _state = e.target.value;
    let componentID = e.target.name;
    // console.log(_state);

    document.getElementById('ckTrack1').checked = _state;
    handleSendDataToModal({
      target: {
        name: document.getElementById('ckTrack1').name,
        value: document.getElementById('ckTrack1').checked,
      },
    });
    document.getElementById('ckTrack2').checked = _state;
    handleSendDataToModal({
      target: {
        name: document.getElementById('ckTrack2').name,
        value: document.getElementById('ckTrack2').checked,
      },
    });
    document.getElementById('ckTrack21').checked = _state;
    handleSendDataToModal({
      target: {
        name: document.getElementById('ckTrack21').name,
        value: document.getElementById('ckTrack21').checked,
      },
    });
    document.getElementById('ckTrack22').checked = _state;
    handleSendDataToModal({
      target: {
        name: document.getElementById('ckTrack22').name,
        value: document.getElementById('ckTrack22').checked,
      },
    });
    document.getElementById('ckTrack3').checked = _state;
    handleSendDataToModal({
      target: {
        name: document.getElementById('ckTrack3').name,
        value: document.getElementById('ckTrack3').checked,
      },
    });
    document.getElementById('allreScale1').hidden = !_state;
    document.getElementById('allreScale2').hidden = !_state;
    //id='allreSort1'
  };
  // PnD
  const handleSendDataToModalPnDAll = (e) => {
    let _state = e.target.value;
    let componentID = e.target.name;
    // console.log(_state);

    document.getElementById('ckPnD1').checked = _state;
    handleSendDataToModal({
      target: {
        name: document.getElementById('ckPnD1').name,
        value: document.getElementById('ckPnD1').checked,
      },
    });
    document.getElementById('ckPnD2').checked = _state;
    handleSendDataToModal({
      target: {
        name: document.getElementById('ckPnD2').name,
        value: document.getElementById('ckPnD2').checked,
      },
    });
    document.getElementById('ckPnD22').checked = _state;
    handleSendDataToModal({
      target: {
        name: document.getElementById('ckPnD22').name,
        value: document.getElementById('ckPnD22').checked,
      },
    });
    document.getElementById('ckPnD21').checked = _state;
    handleSendDataToModal({
      target: {
        name: document.getElementById('ckPnD21').name,
        value: document.getElementById('ckPnD21').checked,
      },
    });
    document.getElementById('allPnD1').hidden = !_state;
    document.getElementById('allPnD1').hidden = !_state;
    //id='allreSort1'
  };
  //handleSendDataToModalReScaleAll
  const [isOpenM, setIsOpenM] = useState(false);
  const handleClickMobile = () => {
    if (isOpenM == false) {
      document.getElementById('leftIcon').hidden = false;
    } else {
      document.getElementById('leftIcon').hidden = true;
    }
    setIsOpenM(!isOpenM); // Toggle open state on click
  };
  return (
    <>
      <nav className="navbar header-navbar pcoded-header">
        <div className="navbar-wrapper">
          <div className="navbar-logo" style={{ padding: '0' }}>
            <a href={'#' + config.routes.dashboard}>
              <img
                className="img-fluid"
                style={{ height: '75px' }}
                src="./files/assets/images/logoTLS.png"
                alt="Theme-Logo"
              />
            </a>
            {/* <a className="mobile-menu" id="mobile-collapse" href="#!">
              <i className="feather icon-menu icon-toggle-right"></i>
            </a> */}
            <a className="mobile-options waves-effect waves-light" onClick={handleClickMobile}>
              <i className="feather icon-more-vertical"></i>
            </a>
          </div>
          <div className="navbar-container container-fluid">
            <ul className="nav-left">
              <li>
                <a onClick={window.toggleFullScreen} className="waves-effect waves-light">
                  <i className="full-screen feather icon-maximize"></i>
                </a>
              </li>
            </ul>
            <ul className="nav-right">
              {location.pathname.includes('dashboard') && (
                <li className="header-notification">
                  <div className="dropdown-primary dropdown">
                    <div
                      className="displayChatbox dropdown-toggle"
                      onClick={handleClick}
                      ref={chatboxRef}
                      style={{ transition: isOpen ? 'slide 500ms ease-in-out' : 'none' }}
                    >
                      <i className="feather icon-settings"></i>
                    </div>
                  </div>
                </li>
              )}
              <li className="header-notification">
                <div className="dropdown-primary dropdown">
                  <div className="dropdown-toggle" data-bs-toggle="dropdown">
                    <i className="feather icon-layers" title="Products"></i>
                  </div>
                  <ul
                    className="show-notification notification-view dropdown-menu"
                    data-dropdown-in="fadeIn"
                    data-dropdown-out="fadeOut"
                  >
                    <li style={{ textAlign: '-webkit-center' }}>
                      <h6 style={{ fontSize: '20px' }}>TLS Apps</h6>
                    </li>
                    <li style={{ padding: '0', pointerEvents: 'none' }}>
                      {' '}
                      <hr style={{ padding: '0' }}></hr>
                    </li>
                    <li style={{ padding: '5' }}>
                      <a href={'#' + config.routes.dashboard}>
                        <div className="d-flex">
                          <div className="flex-shrink-0">
                            <i className="feather icon-home img-radius" style={{ fontSize: '34px' }}></i>
                          </div>
                          <div className="flex-grow-1">
                            <h5 className="notification-user" style={{ marginTop: '10px', marginLeft: '5px' }}>
                              TLS's Dashboard
                            </h5>
                          </div>
                          <div className="flex-end" style={{ marginTop: '10px' }}>
                            <Tooltip content="Open in new window" idTool="mytooltip1">
                              <a href={'#' + config.routes.dashboard} target="_blank">
                                <i className="fa fa-ellipsis-v"></i>
                              </a>
                            </Tooltip>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li style={{ padding: '0', pointerEvents: 'none' }}>
                      {' '}
                      <hr style={{ padding: '0' }}></hr>
                    </li>

                    {String(userData.appAccess).includes('reScale') && (
                      <li style={{ padding: '5' }}>
                        <a href={'http://' + window.location.hostname + '/reScale/#/dashboard'}>
                          <div className="d-flex">
                            <div className="flex-shrink-0">
                              <img
                                className="img-radius"
                                src="./files/assets/images/reScalelogo_singleBlack.png"
                                alt="Generic placeholder "
                              />
                            </div>
                            <div className="flex-grow-1">
                              <h5 className="notification-user" style={{ paddingTop: '10px' }}>
                                reScale
                              </h5>
                            </div>
                            <div className="flex-end" style={{ marginTop: '10px' }}>
                              <Tooltip content="Open in new window" idTool="mytooltip2">
                                <a href={'http://' + window.location.hostname + '/reScale/#/dashboard'} target="_blank">
                                  <i className="fa fa-ellipsis-v"></i>
                                </a>
                              </Tooltip>
                            </div>
                          </div>
                        </a>
                      </li>
                    )}

                    {String(userData.appAccess).includes('reSort') && (
                      <li style={{ padding: '5' }}>
                        <a href={'http://' + window.location.hostname + '/reSort/#/dashboard'}>
                          <div className="d-flex">
                            <div className="flex-shrink-0">
                              <img
                                className="img-radius"
                                src="./files/assets/images/logoreSort_singleBlack.png"
                                alt="Generic placeholder "
                              />
                            </div>
                            <div className="flex-grow-1">
                              <h5 className="notification-user" style={{ paddingTop: '10px' }}>
                                reSort
                              </h5>
                            </div>
                            <div className="flex-end" style={{ marginTop: '10px' }}>
                              <Tooltip content="Open in new window" idTool="mytooltip3">
                                <a href={'http://' + window.location.hostname + '/reSort/#/dashboard'} target="_blank">
                                  <i className="fa fa-ellipsis-v"></i>
                                </a>
                              </Tooltip>
                            </div>
                          </div>
                        </a>
                      </li>
                    )}
                    {String(userData.appAccess).includes('PnD') && (
                      <li style={{ padding: '5' }}>
                        <a href={'http://' + window.location.hostname + '/PnD/#/dashboard'}>
                          <div className="d-flex">
                            <div className="flex-shrink-0">
                              <img
                                className="img-radius"
                                src="./files/assets/images/pnd_Icon.svg"
                                alt="Generic placeholder "
                              />
                            </div>
                            <div className="flex-grow-1">
                              <h5 className="notification-user" style={{ paddingTop: '10px' }}>
                                PnD
                              </h5>
                            </div>
                            <div className="flex-end" style={{ marginTop: '10px' }}>
                              <Tooltip content="Open in new window" idTool="mytooltip3">
                                <a href={'http://' + window.location.hostname + '/PnD/#/dashboard'} target="_blank">
                                  <i className="fa fa-ellipsis-v"></i>
                                </a>
                              </Tooltip>
                            </div>
                          </div>
                        </a>
                      </li>
                    )}
                    {/* <li style={{ padding: "5", opacity:0.5 }}>
                      <a  >
                        <div className="d-flex">
                          <div className="flex-shrink-0">
                            <img
                              className="img-radius"
                              src="./files/assets/images/pnd_Icon.svg"
                              alt="Generic placeholder "
                            />
                          </div>
                          <div className="flex-grow-1">
                            <h5 className="notification-user" style={{ paddingTop: "10px" }}>P&D</h5>
                          </div>
                          <div className="flex-end" style={{ marginTop: "10px" }}>
                            <a target='_blank'>
                              <i className="fa fa-ellipsis-v"></i>
                            </a>
                          </div>
                        </div>
                      </a>
                    </li> */}
                    <li style={{ padding: '5', opacity: 0.5 }}>
                      <a>
                        <div className="d-flex">
                          <div className="flex-shrink-0">
                            <img
                              className="img-radius"
                              src="./files/assets/images/tntIcon.svg"
                              alt="Generic placeholder "
                            />
                          </div>
                          <div className="flex-grow-1">
                            <h5 className="notification-user" style={{ paddingTop: '10px' }}>
                              T&T
                            </h5>
                          </div>
                          <div className="flex-end" style={{ marginTop: '10px' }}>
                            <a target="_blank">
                              <i className="fa fa-ellipsis-v"></i>
                            </a>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li style={{ padding: '0', pointerEvents: 'none' }}>
                      {' '}
                      <hr style={{ padding: '0' }}></hr>
                    </li>
                    {String(userData.appRole).includes('tlsAdmin') && (
                      <li style={{ padding: '5' }}>
                        <a href={'#' + config.routes.user}>
                          <div className="d-flex">
                            <div className="flex-shrink-0">
                              <i className="feather icon-grid img-radius" style={{ fontSize: '34px' }}></i>
                            </div>
                            <div className="flex-grow-1">
                              <h5 className="notification-user" style={{ marginTop: '10px', marginLeft: '5px' }}>
                                Users And Roles
                              </h5>
                            </div>
                            <div className="flex-end" style={{ marginTop: '10px' }}>
                              <Tooltip content="Open in new window" idTool="mytooltip4">
                                <a href={'#' + config.routes.user} target="_blank">
                                  <i className="fa fa-ellipsis-v"></i>
                                </a>
                              </Tooltip>
                            </div>
                          </div>
                        </a>
                      </li>
                    )}
                    {String(userData.appRole).includes('tlsAdmin') && (
                      <li style={{ padding: '5' }}>
                        <a href={'#' + config.routes.company}>
                          <div className="d-flex">
                            <div className="flex-shrink-0">
                              <i
                                className="feather icofont bg-c-black icofont-contacts img-radius"
                                style={{ fontSize: '34px' }}
                              ></i>
                            </div>
                            <div className="flex-grow-1">
                              <h5 className="notification-user" style={{ marginTop: '10px', marginLeft: '5px' }}>
                                Customers
                              </h5>
                            </div>
                            <div className="flex-end" style={{ marginTop: '10px' }}>
                              <Tooltip content="Open in new window" idTool="mytooltip5">
                                <a href={'#' + config.routes.company} target="_blank">
                                  <i className="fa fa-ellipsis-v"></i>
                                </a>
                              </Tooltip>
                            </div>
                          </div>
                        </a>
                      </li>
                    )}
                  </ul>
                </div>
              </li>

              {/* <li className="header-notification">
                <div className="dropdown-primary dropdown">
                  <div className="displayChatbox dropdown-toggle" data-bs-toggle="dropdown">
                    <i className="feather icon-message-square"></i>
                    <span className="badge bg-c-green">3</span>
                  </div>
                </div>
              </li> */}
              <li className="user-profile header-notification">
                <div className="dropdown-primary dropdown">
                  <div className="dropdown-toggle" data-bs-toggle="dropdown">
                    <img
                      src="../files/assets/images/avatar-4.jpg"
                      className="img-radius"
                      style={{ marginTop: '10px' }}
                      alt="User-Profile"
                    />
                    <span>{userLogin.user}</span>
                    <i className="feather icon-chevron-down"></i>
                  </div>
                  <ul
                    className="show-notification profile-notification dropdown-menu"
                    data-dropdown-in="fadeIn"
                    data-dropdown-out="fadeOut"
                  >
                    <li>{userLogin.user}</li>
                    <li>
                      <a>version 1.2</a>
                    </li>
                    {/* <li>
                      <a href="/#">
                        <i className="feather icon-user"></i> Profile
                      </a>
                    </li>
                    <li>
                      <a href="email-inbox.html">
                        <i className="feather icon-mail"></i> My Messages
                      </a>
                    </li>
                    <li>
                      <a href="auth-lock-screen.html">
                        <i className="feather icon-lock"></i> Lock Screen
                      </a>
                    </li> */}
                    <li>
                      <NavLink
                        to="/"
                        // type="button"
                        // className="btn btn-primary btn-md waves-effect text-center m-b-20"
                        onClick={handleLogout}
                      >
                        <i className="feather icon-log-out"></i> Sign Out
                      </NavLink>
                      {/* <a>
                        <i className="feather icon-log-out"></i> Logout
                      </a> */}
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div id="sidebar" className="users p-chat-user showChat" style={{ display: 'block' }} hidden>
        <div className="had-container">
          <div className="p-fixed users-main">
            <div className="user-box" style={{ overflowY: 'auto' }}>
              <div className="chat-search-box">
                <a className="back_friendlist" onClick={handleClick}>
                  <i className="feather icon-x"></i>
                </a>
                <div className="right-icon-control">
                  <div className="input-group input-group-button">
                    <h4>Customize Dashboard</h4>
                  </div>

                  {String(userData.appAccess).includes('reScale') && <hr style={{ padding: '0' }}></hr>}

                  {String(userData.appAccess).includes('reScale') && (
                    <table className="table table-hover m-b-0 without-header">
                      <tr>
                        <td>
                          <h5>reScale's widgets</h5>
                        </td>
                        <td>
                          <input
                            type="checkbox"
                            id="ckScaleWALL"
                            name=""
                            //  checked="true"
                            onChange={(e) => {
                              handleSendDataToModalreScaletAll({
                                target: {
                                  name: e.target.name,
                                  value: e.target.checked,
                                },
                              });
                            }}
                            defaultChecked
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>Jobs Analysis Chart</span>
                        </td>
                        <td>
                          <input
                            id="ckTrack1"
                            type="checkbox"
                            name="wTrack1"
                            //  checked="true"
                            onChange={(e) => {
                              handleSendDataToModal({
                                target: {
                                  name: e.target.name,
                                  value: e.target.checked,
                                },
                              });
                            }}
                            defaultChecked
                          />{' '}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>All Last 24h </span>
                        </td>
                        <td>
                          <input
                            id="ckTrack2"
                            type="checkbox"
                            name="wTrack2"
                            //  checked="true"
                            onChange={(e) => {
                              handleSendDataToModal({
                                target: {
                                  name: e.target.name,
                                  value: e.target.checked,
                                },
                              });
                            }}
                            defaultChecked
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>Batch Last 24h </span>
                        </td>
                        <td>
                          <input
                            id="ckTrack21"
                            type="checkbox"
                            name="wTrack21"
                            //  checked="true"
                            onChange={(e) => {
                              handleSendDataToModal({
                                target: {
                                  name: e.target.name,
                                  value: e.target.checked,
                                },
                              });
                            }}
                            defaultChecked
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>On Demand Last 24h </span>
                        </td>
                        <td>
                          <input
                            id="ckTrack22"
                            type="checkbox"
                            name="wTrack22"
                            //  checked="true"
                            onChange={(e) => {
                              handleSendDataToModal({
                                target: {
                                  name: e.target.name,
                                  value: e.target.checked,
                                },
                              });
                            }}
                            defaultChecked
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>Ticket</span>
                        </td>
                        <td>
                          <input
                            id="ckTrack3"
                            type="checkbox"
                            name="wTrack3"
                            //  checked="true"
                            onChange={(e) => {
                              handleSendDataToModal({
                                target: {
                                  name: e.target.name,
                                  value: e.target.checked,
                                },
                              });
                            }}
                            defaultChecked
                          />{' '}
                        </td>
                      </tr>
                    </table>
                  )}
                  {String(userData.appAccess).includes('reSort') && <hr style={{ padding: '0' }}></hr>}
                  {String(userData.appAccess).includes('reSort') && (
                    <table className="table table-hover m-b-0 without-header">
                      <tr>
                        <td>
                          <h5>reSort's widgets</h5>
                        </td>
                        <td>
                          <input
                            type="checkbox"
                            id="ckSortWALL"
                            name=""
                            //  checked="true"
                            onChange={(e) => {
                              handleSendDataToModalResortAll({
                                target: {
                                  name: e.target.name,
                                  value: e.target.checked,
                                },
                              });
                            }}
                            defaultChecked
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>Jobs</span>
                        </td>
                        <td>
                          <input
                            type="checkbox"
                            id="ckSort1"
                            name="wSort1"
                            //  checked="true"
                            onChange={(e) => {
                              handleSendDataToModal({
                                target: {
                                  name: e.target.name,
                                  value: e.target.checked,
                                },
                              });
                            }}
                            defaultChecked
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>Latest Job</span>
                        </td>
                        <td>
                          <input
                            type="checkbox"
                            id="ckSort2"
                            name="wSort2"
                            //  checked="true"
                            onChange={(e) => {
                              handleSendDataToModal({
                                target: {
                                  name: e.target.name,
                                  value: e.target.checked,
                                },
                              });
                            }}
                            defaultChecked
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>PostNord</span>
                        </td>
                        <td>
                          <input
                            type="checkbox"
                            id="ckSort3"
                            name="wSort3"
                            //  checked="true"
                            onChange={(e) => {
                              handleSendDataToModal({
                                target: {
                                  name: e.target.name,
                                  value: e.target.checked,
                                },
                              });
                            }}
                            defaultChecked
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>Bring</span>
                        </td>
                        <td>
                          <input
                            type="checkbox"
                            id="ckSort4"
                            name="wSort4"
                            //  checked="true"
                            onChange={(e) => {
                              handleSendDataToModal({
                                target: {
                                  name: e.target.name,
                                  value: e.target.checked,
                                },
                              });
                            }}
                            defaultChecked
                          />{' '}
                        </td>
                      </tr>
                    </table>
                  )}
                  {String(userData.appAccess).includes('PnD') && <hr style={{ padding: '0' }}></hr>}
                  {String(userData.appAccess).includes('PnD') && (
                    <table className="table table-hover m-b-0 without-header">
                      <tr>
                        <td>
                          <h5>PnD's widgets</h5>
                        </td>
                        <td>
                          <input
                            type="checkbox"
                            id="ckPnDALL"
                            name=""
                            //  checked="true"
                            onChange={(e) => {
                              handleSendDataToModalPnDAll({
                                target: {
                                  name: e.target.name,
                                  value: e.target.checked,
                                },
                              });
                            }}
                            defaultChecked
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>Analysis</span>
                        </td>
                        <td>
                          <input
                            id="ckPnD1"
                            type="checkbox"
                            name="wPnD1"
                            //  checked="true"
                            onChange={(e) => {
                              handleSendDataToModal({
                                target: {
                                  name: e.target.name,
                                  value: e.target.checked,
                                },
                              });
                            }}
                            defaultChecked
                          />{' '}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>Active jobs</span>
                        </td>
                        <td>
                          <input
                            id="ckPnD2"
                            type="checkbox"
                            name="wPnD2"
                            //  checked="true"
                            onChange={(e) => {
                              handleSendDataToModal({
                                target: {
                                  name: e.target.name,
                                  value: e.target.checked,
                                },
                              });
                            }}
                            defaultChecked
                          />
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <span>Finished jobs</span>
                        </td>
                        <td>
                          <input
                            id="ckPnD22"
                            type="checkbox"
                            name="wPnD22"
                            //  checked="true"
                            onChange={(e) => {
                              handleSendDataToModal({
                                target: {
                                  name: e.target.name,
                                  value: e.target.checked,
                                },
                              });
                            }}
                            defaultChecked
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>Documents </span>
                        </td>
                        <td>
                          <input
                            id="ckPnD21"
                            type="checkbox"
                            name="wPnD21"
                            //  checked="true"
                            onChange={(e) => {
                              handleSendDataToModal({
                                target: {
                                  name: e.target.name,
                                  value: e.target.checked,
                                },
                              });
                            }}
                            defaultChecked
                          />
                        </td>
                      </tr>
                    </table>
                  )}
                  {/* new  */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
