import { useState, useEffect, lazy, useLayoutEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { useStore, actions, handles } from '../../store';
import Cookies from 'js-cookie';
import Widget from './PnDWidget';

function Default() {
  //store
  const { state, dispatch } = useStore();
  const { hubConnection, isCheckScript } = state;
  const { toggleCheckScript } = actions;
  const { ChartDashBoardAll, ChartPieLatestBatch } = handles;
  const { runExistingScripts, getDate } = handles;
  //useRef

  const [dataLast24h, setDataLast24h] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reSortBatchAll, setReSortBatchAll] = useState([]);
  const [reSortBatchMonth, setReSortBatchMonth] = useState();
  const [reSortBatchDay, setReSortBatchDay] = useState();
  const [openTicket, setOpenTicket] = useState(null);
  const [closeTicket, setCloseTicket] = useState(null);
  const [dataLicense, setDataLicense] = useState();
  const [ticketRescale, setTicketRescale] = useState([]);
  const [countDocument, setCountDocument] = useState([]);
  const [countSheet, setCountSheet] = useState([]);
  const [dataAll, setDataAll] = useState([]);
  const [counter, setCounter] = useState(0);
  const [dataBatchInMonth, setDataBatchInMonth] = useState();
  const [dataBatchStat, setDataBatchStat] = useState([]);
  const [userData, setuserData] = useState({});
  const userLogin = JSON.parse(Cookies.get('loggedInUser'));
  //var dataLicense
  //useLayoutEffect
  // onChange Connect
  useLayoutEffect(() => {
    runExistingScripts(dispatch, toggleCheckScript, isCheckScript);
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (hubConnection) {
      //invoke
      let dataUserCheck;
      // user
      hubConnection.invoke('ReadUsers').catch((err) => console.log(err));
      //on
      hubConnection.on('SendReadUsers', (users) => {
        dataUserCheck = users;
      });
      //invoke
      hubConnection.invoke('ReadJobsDashboard').catch((err) => console.log(err));
      //
      hubConnection.on('SendReadJobsDashboard', (param) => {
        // console.log('finnish job : ', param);
        setDataLast24h(param);
      });
      // ticket
      hubConnection.invoke('ReadTicketJobs').catch((err) => console.log(err));
      hubConnection.on('SendReadTicketJobs', (param) => {
        let user;

        dataUserCheck.map((data) => {
          if (data.user === userLogin.user) {
            user = data;
          }
        });


        let dataCheck = [];
        param.map((data) => {
          let userAsignee = data.ticketAssignee.split(',');
          let permissionUser = JSON.parse(user.permission);
          if (permissionUser.includes('admin')) {
            dataCheck.push(data);
          } else if (userAsignee.includes(userLogin.user)) {
            dataCheck.push(data);
          }
        });
        //
        let dataContOpen = 0;
        let dataCountClose = 0;
        dataCheck.map((data) => {
          if (data.ticketStatus === 3) {
            dataCountClose += 1;
          } else {
            dataContOpen += 1;
          }
        });

        setCloseTicket(dataCountClose);
        setOpenTicket(dataContOpen);
      });
      // current user info
      hubConnection.invoke('ReadEmailUserByUserName', userLogin.user).catch((err) => console.log(err));

      hubConnection.on('SendReadEmailUserByUserName', (param) => {
        setuserData(param);

      });
    }
    fetch('http://51.12.208.17:3008/api/distributorsstatistics')
      .then(response => response.json())
      .then(data => setDataAll(data))
      .catch(error => console.error('Error fetching data:', error));
    // Fetch data from API
    fetch('http://51.12.208.17:3008/api/countbatchesinmonth')
      .then(response => response.json())
      .then(data => setDataBatchInMonth(data))
      .catch(error => console.error('Error fetching data:', error));

    fetch('http://51.12.208.17:3008/api/LatestBatchnStatistics')
      .then(response => response.json())
      .then(data => { setDataBatchStat(data); ChartPieLatestBatch(data) })
      .catch(error => console.error('Error fetching data:', error));

    fetch('http://51.12.208.17:3008/api/batch/count')
      .then(response => response.json())
      .then(data => setReSortBatchDay(data))
      .catch(error => console.error('Error fetching data:', error));

    fetch('http://51.12.208.17:3008/api/countbatchesinmonth')
      .then(response => response.json())
      .then(data => setReSortBatchMonth(data))
      .catch(error => console.error('Error fetching data:', error));

    fetch('http://51.12.208.17:3008/api/allbatch')
      .then(response => response.json())
      .then(data => setReSortBatchAll(data))
      .catch(error => console.error('Error fetching data:', error));


    fetch('http://51.12.208.17:3008/api/countdocumentinMonth')
      .then(response => response.json())
      .then(data => setCountDocument(data))
      .catch(error => console.error('Error fetching data:', error));
    fetch('http://51.12.208.17:3008/api/countsheetinMonth')
      .then(response => response.json())
      .then(data => setCountSheet(data))
      .catch(error => console.error('Error fetching data:', error));
  }, [hubConnection]);
  // onChange Connect
  useEffect(() => {
    if (hubConnection && dataLast24h.length == 0) {
      hubConnection.invoke('ReadJobsDashboard').catch((err) => console.log(err));
      //
      hubConnection.on('SendReadJobsDashboard', (param) => {
        // console.log('finnish job : ', param);
        setDataLast24h(param);

      });
    }


    ChartDashBoardAll(dataLast24h);

  }, [ChartDashBoardAll, dataLast24h]);

  useEffect(() => {
    const reNewData = async () => {
      if (hubConnection) {
        fetch('http://51.12.208.17:3008/api/LatestBatchnStatistics')
          .then(response => response.json())
          .then(data => { setDataBatchStat(data); ChartPieLatestBatch(data) })
          .catch(error => console.error('Error fetching data:', error));
        console.log('call lastest job data')

      }

    };
    reNewData();
    // const intervalId = setInterval(reNewData, 30000); // 3s in milliseconds

    // Cleanup function to clear the interval on unmount
    // return () => clearInterval(intervalId);

  }, []);


  useEffect(() => {
    if (hubConnection) {
      hubConnection.invoke('ReadLicense').catch((err) => console.log("lic error: " + err));
      hubConnection.on('SendReadLicense', (param) => {
        // console.log('param key:', param);
        const dataLicenseKey = JSON.parse(param);
        console.log('license key:', dataLicenseKey);
        setDataLicense(dataLicenseKey);
      });
    }
  }, [hubConnection]);
  console.log(`dataLicense: ${dataLicense}`);
  const handleDemo = () => {
    const demo = [
      { templateId: 1, templateName: 'Hieudemo', templateBody: 'Demo body da update nha 11' },
      { templateId: 2, templateName: 'Hieudemo1', templateBody: 'Demo body1 da update tiep nha' },
    ];

    hubConnection.invoke('CrudDemo', demo).catch((err) => console.log(err));
  };



  return (
    <div className={`pcoded-content${loading ? ' card card-load' : ''}`}>

      <div className="page-header card">
        <div className="row align-items-end">
          <div className="col-lg-8">
            <div className="page-header-title">
              <i className="feather icon-home bg-c-blue"></i>
              <div className="d-inline">
                <h5>Dashboard</h5>
              </div>
              {/* <span>lorem ipsum dolor sit amet, consectetur adipisicing elit</span> */}
            </div>
          </div>
          <div className="col-lg-4">
            <div className="page-header-breadcrumb">
              <ul className=" breadcrumb breadcrumb-title breadcrumb-padding">
                {/* <li className="breadcrumb-item">
                  <NavLink to="/dashboard">
                    <i className="feather icon-home"></i>
                  </NavLink>
                </li>
                <li className="breadcrumb-item">
                  <a href=""> </a>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="pcoded-inner-content">
        <div className="main-body">
          <div className="page-wrapper">
            <div className="page-body">
              <div className="row">


              </div>
              <div className="row">
                <div className="col-md-12 col-xl-8">
                  {/* <div className="card sale-card">
                    <div className="card-header">
                      <h5>Deals Analytics</nnnh5>
                    </div> */}
                  {/* new */}
                  <div className="card sale-card">
                    <div className="card-header">
                      <img src="./files/assets/images/logoTLSsingleblack.png" style={{ width: "25px", marginRight: "5px" }} />
                      <h5>APPLICATION LIST</h5>
                    </div>
                    <div className="card-block">
                      <div className="table-responsive">
                        <table className="table m-b-0  table-borderless">
                          <thead>
                            <tr>
                              <th>Application </th>
                              <th>License type</th>
                              <th>User</th>
                              <th>Expiration Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {dataLicense?.Applications.map((x, index) =>
                              <tr key={index}>
                                <td>
                                  <div className="d-inline-block align-middle">
                                    <h6>{x.Application}</h6>
                                    <p className="text-muted m-b-0"></p>
                                  </div>
                                </td>
                                <td>{x.Type}</td>
                                <td>{x.User}</td>

                                <td className="text-c-blue">{getDate(x.Valid)}</td>
                              </tr>
                            )}

                          </tbody>
                        </table>
                      </div>
                    </div>

                  </div>
                  {/* new */}

                  {/* <div className="card-block">
                      <div id="sales-analytics" className="chart-shadow" style={{ height: 380 }}></div>
                    </div> */}
                  {/* </div> */}
                </div>
              </div>
              {String(userData.appAccess).includes("reScale") && (<><div id='allreScale1' style={{ display: "flex", alignItems: "center" }}><b>reScale's widgets</b></div>
                <hr id='allreScale2' style={{ marginTop: "1px", backgroundColor: "black", opacity: 1 }}></hr>
              </>)}

              {String(userData.appAccess).includes("reScale") && <div className='row'>
                <div className="col-md-12 col-xl-8" id="wTrack1" >
                  <div className="card sale-card" style={{ paddingBottom: "10px" }}>
                    <div className="card-header">
                      <img src="./files/assets/images/reScalelogo_singleBlack.png" style={{ width: "25px", marginRight: "5px" }} />
                      <h5>JOBS ANALYSIS CHART</h5>

                    </div>
                    <div className="card-block">
                      <div id="deal-analytic-chart" className="chart-shadow" style={{ height: '360px' }}></div>
                    </div>
                    <div id="tot-rev-chart" style={{ display: 'none' }}></div>
                    <div id="status-round-1" style={{ display: 'none' }}></div>
                    <div id="status-round-2" style={{ display: 'none' }}></div>
                    <div id="status-round-3" style={{ display: 'none' }}></div>
                    <div id="status-round-4" style={{ display: 'none' }}></div>
                  </div>
                </div>
                <div className="col-md-12 col-xl-4" >
                  <div className="card comp-card" id="wTrack2">
                    <div className="card-body">
                      <div className="row align-items-center">
                        <div className="col ">

                          <h6 className="m-b-25" style={{ fontWeight: 700 }}><img src="./files/assets/images/reScalelogo_singleBlack.png" style={{ width: "25px", marginRight: "5px" }} />ALL LAST 24h</h6>
                          <div className="row text-center">
                            <div className="col">
                              <h3 className="f-w-700 text-c-blue">{dataLast24h.length}</h3>
                              <p className="m-b-0 text-c-blue">All</p>
                            </div>
                            <div className="col">
                              <h3 className="f-w-700 text-c-red">
                                {dataLast24h.filter((job) => job.status === 'Failed').length}
                              </h3>
                              <p className="m-b-0 text-c-red">Failed</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-auto">
                          {/* <i className="fas fa-eye bg-c-blue" style={{borderRadius:"5"}}></i> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card comp-card" id="wTrack21">
                    <div className="card-body">
                      <div className="row align-items-center">
                        <div className="col ">
                          <h6 className="m-b-25" style={{ fontWeight: 700 }}><img src="./files/assets/images/reScalelogo_singleBlack.png" style={{ width: "25px", marginRight: "5px" }} />ON DEMAND LAST 24h</h6>
                          <div className="row text-center">
                            <div className="col">
                              <h3 className="f-w-700" style={{ color: '#35ed21' }}>
                                {dataLast24h.filter((job) => job.workflowGroup === 'ONLINE').length}
                              </h3>
                              <p className="m-b-0" style={{ color: '#35ed21' }}>
                                All
                              </p>
                            </div>
                            <div className="col">
                              <h3 className="f-w-700 text-c-blue">
                                {
                                  dataLast24h.filter(
                                    (job) => job.workflowGroup === 'ONLINE' && job.status === 'Completed',
                                  ).length
                                }
                              </h3>

                              <p className="m-b-0 text-c-blue ">Completed</p>
                            </div>
                            <div className="col">
                              <h3 className="f-w-700" style={{ color: '#21ede6' }}>
                                {
                                  dataLast24h.filter((job) => job.workflowGroup === 'ONLINE' && job.status === 'Failed')
                                    .length
                                }
                              </h3>
                              <p className="m-b-0" style={{ color: '#21ede6' }}>
                                Failed
                              </p>
                            </div>
                            <div className="col">
                              <h3 className="f-w-700 text-c-blue">
                                {
                                  dataLast24h.filter(
                                    (job) =>
                                      job.workflowGroup === 'ONLINE' && job.endTimestamp - job.startTimestamp > 2000,
                                  ).length
                                }
                              </h3>
                              <p className="m-b-0 text-c-blue ">Duration{'>'}2s</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-auto">
                          {/* <i className="fas fa-bullseye bg-c-green"></i> */}
                        </div>
                      </div>

                    </div>
                  </div>
                  <div className="card comp-card" id="wTrack22">
                    <div className="card-body">
                      <div className="row align-items-center">
                        <div className="col">
                          <h6 className="m-b-25" style={{ fontWeight: 700 }}><img src="./files/assets/images/reScalelogo_singleBlack.png" style={{ width: "25px", marginRight: "5px" }} />BATCH LAST 24h</h6>
                          <div className="row text-center">
                            <div className="col">
                              <h3 className="f-w-700" style={{ color: '#ede621' }}>
                                {dataLast24h.filter((job) => job.workflowGroup === 'BATCH').length}
                              </h3>
                              <p className="m-b-0" style={{ color: '#ede621' }}>
                                All
                              </p>
                            </div>
                            <div className="col">
                              <h3 className="f-w-700 text-c-blue">
                                {
                                  dataLast24h.filter((job) => job.workflowGroup === 'BATCH' && job.status !== 'Failed')
                                    .length
                                }
                              </h3>
                              <p className="m-b-0 text-c-blue">Completed</p>
                            </div>
                            <div className="col">
                              {/* #d221ed */}
                              <h3 className="f-w-700" style={{ color: '#d221ed' }}>
                                {
                                  dataLast24h.filter((job) => job.workflowGroup === 'BATCH' && job.status === 'Failed')
                                    .length
                                }
                              </h3>
                              <p className="m-b-0 " style={{ color: '#d221ed' }}>
                                Failed
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-auto">
                          {/* <i className="fas fa-hand-paper bg-c-yellow" style={{borderRadius:"0% !important"}}></i> */}
                        </div>
                      </div>
                    </div>
                  </div>

                </div>



                <div className="col-xl-4" id="wTrack3">
                  <div className="card comp-card">
                    <div className="card-body">
                      <div className="row align-items-center">
                        <div className="col ">

                          <h6 className="m-b-25" style={{ fontWeight: 700 }}><img src="./files/assets/images/reScalelogo_singleBlack.png" style={{ width: "25px", marginRight: "5px" }} />TICKET</h6>
                          <div className="row text-center">
                            <div className="col">
                              <h3 className="f-w-700 text-c-blue">{openTicket || 0}</h3>
                              <p className="m-b-0 text-c-blue">Open</p>
                            </div>
                            <div className="col">
                              <h3 className="f-w-700 text-c-red">
                                {closeTicket || 0}
                              </h3>
                              <p className="m-b-0 text-c-red">Close</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-auto">
                          {/* <i className="fas fa-eye bg-c-blue" style={{borderRadius:"5"}}></i> */}
                        </div>
                      </div>
                    </div>
                  </div>

                </div></div>}
              {String(userData.appAccess).includes("reSort") && (<><div id='allreSort1' style={{ display: "flex", alignItems: "center" }}><b>reSort's widgets</b></div>
                <hr id='allreSort2' style={{ marginTop: "1px", backgroundColor: "black", opacity: 1 }}></hr></>)}
              {/* reSort widget*/}
              {String(userData.appAccess).includes("reSort") && <div className='row'>
                <div className="col-md-12" id="wSort1">
                  <div className="card product-progress-card">
                    <div className="card-block">
                      <div className="row pp-main">
                        <div className="col-xl-4 col-md-6">
                          <div className="pp-cont">
                            <div className="row align-items-center m-b-20">
                              <div className="col-auto">
                                <img src="./files/assets/images/logoreSort_singleBlack.png" style={{ width: "30px", marginRight: "5px" }} />
                              </div>
                              <div className="col text-end">
                                <h2 className="m-b-0 text-c-blue">{new Intl.NumberFormat().format(dataBatchInMonth?.count)}</h2>
                              </div>
                            </div>
                            <div className="row align-items-center m-b-15">
                              <div className="col-auto">
                                <p className="m-b-0">Total jobs in the current month</p>
                              </div>
                              <div className="col text-end">
                                {/* <p className="m-b-0 text-c-blue"><i className="fas fa-long-arrow-alt-up m-r-10"></i></p> */}
                              </div>
                            </div>
                            <div className="progress">

                              <div className="progress-bar bg-c-blue" style={{ width: "100%" }}></div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                          <div className="pp-cont">
                            <div className="row align-items-center m-b-20">
                              <div className="col-auto">
                                <img src="./files/assets/images/logoreSort_singleBlack.png" style={{ width: "30px", marginRight: "5px" }} />
                              </div>
                              <div className="col text-end">
                                <h2 className="m-b-0 text-c-red">{new Intl.NumberFormat().format(countDocument?.total_num_documents_in_month)}</h2>
                              </div>
                            </div>
                            <div className="row align-items-center m-b-15">
                              <div className="col-auto">
                                <p className="m-b-0">Total documents in the current month</p>
                              </div>
                              <div className="col text-end">
                                {/* <p className="m-b-0 text-c-red"><i className="fas fa-long-arrow-alt-up m-r-10"></i></p> */}
                              </div>
                            </div>
                            <div className="progress">
                              <div className="progress-bar bg-c-red" style={{ width: "100%" }}></div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                          <div className="pp-cont">
                            <div className="row align-items-center m-b-20">
                              <div className="col-auto">
                                <img src="./files/assets/images/logoreSort_singleBlack.png" style={{ width: "30px", marginRight: "5px" }} />
                              </div>
                              <div className="col text-end">

                                <h2 className="m-b-0 text-c-yellow">{new Intl.NumberFormat().format(countSheet.sum)}</h2>

                              </div>
                            </div>
                            <div className="row align-items-center m-b-15">
                              <div className="col-auto">
                                <p className="m-b-0">Total sheets in the current month</p>
                              </div>
                              <div className="col text-end">
                                {/* <p className="m-b-0 text-c-yellow"><i className="fas fa-long-arrow-alt-up m-r-10"></i></p> */}
                              </div>
                            </div>
                            <div className="progress">
                              <div className="progress-bar bg-c-yellow" style={{ width: "100%" }}></div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>


                <div className="col-md-12 col-xl-4" id="wSort2">
                  <div className="card sale-card">
                    <div className="card-header">
                      <img src="./files/assets/images/logoreSort_singleBlack.png" style={{ width: "25px", marginRight: "5px" }} />
                      <h5>LATEST JOB</h5>
                    </div>
                    <div className="col-xl-12 col-md-4">
                      <div id="allocation-chart" className="chart-shadow" style={{ height: "250px" }}></div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-xl-4" id="wSort3">

                  <div className="card o-hidden">
                    <div className="card-header">
                      <img src="./files/assets/images/logoreSort_singleBlack.png" style={{ width: "25px", marginRight: "5px" }} />
                      <h5 >PostNord </h5>
                    </div>
                    <div className="card-block">
                      {dataAll.length > 0 && dataAll?.map((x, index) =>
                      (

                        <div key={index} className="row">
                          <div className="col-4">
                            <p className="text-muted m-b-5">Total [sheet]</p>
                            <h6>{new Intl.NumberFormat().format(Number(x.NormalzonePostnord) + Number(x.SUM_Postnord_LowZone) + Number(x.SUM_Foreign))}</h6>
                          </div>
                          <div className="col-4">
                            <p className="text-muted m-b-5">Domestic [sheet]</p>
                            <h6>{new Intl.NumberFormat().format(Number(x.NormalzonePostnord) + Number(x.SUM_Postnord_LowZone))}</h6>
                          </div>
                          <div className="col-4">
                            <p className="text-muted m-b-5">Foreign [sheet]</p>
                            <h6>{new Intl.NumberFormat().format(x.SUM_Foreign)}</h6>
                          </div>
                          <div className="col-4">
                            <p className="text-muted m-b-5">Normal zone [sheet]</p>
                            <h6>{new Intl.NumberFormat().format(x.NormalzonePostnord)}</h6>
                          </div>
                          <div className="col-4">
                            <p className="text-muted m-b-5">Low zone [sheet]</p>
                            <h6>{new Intl.NumberFormat().format(x.SUM_Postnord_LowZone)}</h6>
                          </div>
                          <div className="col-4">
                            <p className="text-muted m-b-5">Total price</p>
                            <h6>{new Intl.NumberFormat('se-SE', { style: 'currency', currency: 'SEK' }).format(x.SUM_Postnord_Price)}</h6>
                          </div>
                        </div>

                      ))
                      }
                    </div>
                    {/* <div id="sal-incomeReSort" style={{ height: "100px" }}></div> */}
                  </div>
                </div>
                <div className="col-md-12 col-xl-4" id="wSort4">
                  <div className="card o-hidden">
                    <div className="card-header">
                      <img src="./files/assets/images/logoreSort_singleBlack.png" style={{ width: "25px", marginRight: "5px" }} />
                      <h5>Bring</h5>
                    </div>
                    <div className="card-block">
                      {dataAll.length > 0 && dataAll?.map((x, index) =>
                      (

                        <div key={index} className="row">
                          <div className="col-4">
                            <p className="text-muted m-b-5">Total [sheet]</p>
                            <h6>{new Intl.NumberFormat().format(x.sum_bring_sheets)}</h6>
                          </div>
                          <div className="col-4">
                            <p className="text-muted m-b-5">Domestic [sheet]</p>
                            <h6>{new Intl.NumberFormat().format(Number(x.sum_bring_sheets))}</h6>
                          </div>
                          <div className="col-4">
                            <p className="text-muted m-b-5">Foreign [sheet]</p>
                            <h6>0</h6>
                          </div>

                          <div className="col-4">
                            <p className="text-muted m-b-5">Total price</p>

                            <h6>{new Intl.NumberFormat('se-SE', { style: 'currency', currency: 'SEK' }).format(x.SUM_Bring_Price)}</h6>

                          </div>
                        </div>

                      ))
                      }
                    </div>
                    {/* <div id="rent-incomeReSort" style={{ height: "100px" }}></div> */}
                  </div>

                </div>
              </div>}

              {String(userData.appAccess).includes("PnD") && (<><div id='allPnD1' style={{ display: "flex", alignItems: "center" }}><b>PnD's widgets</b></div>
                <hr id='allPnD2' style={{ marginTop: "1px", backgroundColor: "black", opacity: 1 }}></hr></>)}
              {/* reSort widget*/}
              {String(userData.appAccess).includes("PnD") && <Widget />}


            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Default;
