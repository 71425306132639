import React, { useState, useEffect } from 'react';
import './alert.css'
const Alert = ({ message, duration = 1500 }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => setIsVisible(false), duration);
    return () => clearTimeout(timer);
  }, [duration]);

  return (
    isVisible && (
      <div className="alert-popup">
        <p>{message}</p>
      </div>
    )
  );
};

export default Alert;