import { useEffect, useState, useRef } from 'react';
import { useStore, handles } from '../../store';
import { OpenModal, ModalCustomTimes } from '../../component';
const Widget = () => {
  //
  const { getCookie, ChartDashBoardAll2 } = handles;
  const userLogin = JSON.parse(getCookie('loggedInUser'));
  //
  const { state } = useStore();
  const { hubConnection } = state;
  // useState data
  const [dataChart, setDataChart] = useState([]); //data chart job
  const [dataChartDocument, setDataChartDocument] = useState([]); //data chart document
  const [dataNewJob, setDataNewJob] = useState([]);
  const [dataDraftJob, setDataDraftJob] = useState([]);
  const [dataWidgetJob, setDataWidgetJob] = useState([]);
  const [dataWidgetDocument, setDataWidgetDocument] = useState([]);
  // chart logic
  const [btnChart, setBtnChart] = useState('OneDay');
  const [innerCustom, setInnerCustom] = useState('Custom');
  const [timeFrom, setTimeFrom] = useState(0);
  const [timeTo, setTimeTo] = useState(0);
  // Loading
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingJob, setIsLoadingJob] = useState(true);
  const [isLoadingDocument, setIsLoadingDocument] = useState(true);
  const [isLoadingChart, setIsLoadingChart] = useState(true);
  // Drop job
  const [valueIDDrop, setValueIDDrop] = useState('OneDay'); //to apply api
  const [isOpenDropJob, setIsOpenDropJob] = useState(false);
  const [valueDropJob, setValueDropJob] = useState('Day'); //to show
  // Drop Document
  const [valueIDDropDocument, setValueIDDropDocument] = useState('OneDay'); //to apply api
  const [isOpenDropDocument, setIsOpenDropDocument] = useState(false);
  const [valueDropDocument, setValueDropDocument] = useState('Day'); //to show
  // Logic
  const [isCheckJob, setIsCheckJob] = useState(false);
  const [isCheckDocument, setIsCheckDocument] = useState(false);
  // useRef
  const isCheckLinkJobRef = useRef(false);
  const isCheckLinkDocumentRef = useRef(false);
  const valuebtnChartRef = useRef('OneDay');
  const istypeRef = useRef(3);
  // useEffect API
  useEffect(() => {
    if (hubConnection) {
      // all job (to get new job)
      hubConnection.invoke('ReadFilesAll').catch(console.log);
      hubConnection.on('SendReadFilesAll', (param) => {
        param === null ? setIsLoading(true) : setIsLoading(false);
        setDataNewJob(param.filter((data) => data.status === 0));
        setDataDraftJob(param.filter((data) => data.status === 1 && data.user === userLogin.user));
      });
      // chart ( finish job)---------------------------------------------
      hubConnection.invoke('ReadFilesByTimes', 'OneDay').catch(console.log);
      hubConnection.on('SendReadFilesByTimes', (param) => {
        // param === null ? setIsLoadingChart(true) : setIsLoadingChart(false);
        const dataTimeStamp = param.filter((data) => data.status === 2).map((data) => data.startTimestamp);
        console.log('in change data', dataTimeStamp);
        setDataChart(dataTimeStamp);
      });
      // widget finish job
      hubConnection.invoke('ReadFilesByTimesFinished', 'OneDay').catch(console.log);
      hubConnection.on('SendReadFilesByTimesFinished', (param) => {
        param === null ? setIsLoadingJob(true) : setIsLoadingJob(false);
        const dataTimeStamp = param.filter((data) => data.status === 2).map((data) => data.startTimestamp);
        setDataWidgetJob(dataTimeStamp);
      });
      // document --------------------------------------
      //document chart
      hubConnection.invoke('ReadFilesByDocument', 'OneDay').catch(console.log);
      hubConnection.on('SendReadFilesByDocument', (param) => {
        const dataTimeStamp = param.map((data) => data.startTimestamp);
        setDataChartDocument(dataTimeStamp);
      });
      //document widget
      hubConnection.invoke('ReadFilesByDocumentFinished', 'OneDay').catch(console.log);
      hubConnection.on('SendReadFilesByDocumentFinished', (param) => {
        // console.log(param);
        param === null ? setIsLoadingDocument(true) : setIsLoadingDocument(false);
        const dataTimeStamp = param.map((data) => data.startTimestamp);
        setDataWidgetDocument(dataTimeStamp);
      });
    }
  }, [hubConnection]);
  // khi an 'link...'
  useEffect(() => {
    if (dataChart && isCheckLinkJobRef.current.checked === true) {
      setIsLoadingJob(true);
      setDataWidgetJob(dataChart);
      setTimeout(() => {
        setIsLoadingJob(false);
      }, 1000);
    }
  }, [isCheckJob, dataChart]);
  useEffect(() => {
    if (dataChartDocument && isCheckLinkDocumentRef.current.checked === true) {
      setIsLoadingDocument(true);
      setDataWidgetDocument(dataChartDocument);
      setTimeout(() => {
        setIsLoadingDocument(false);
      }, 1000);
    }
  }, [isCheckDocument, dataChartDocument]);
  // Thay doi data khi an btn - CHART
  useEffect(() => {
    if (btnChart === 'custom-date-chart') {
      ChartDashBoardAll2(dataChart, dataChartDocument, btnChart, timeFrom, timeTo);
    } else {
      ChartDashBoardAll2(dataChart, dataChartDocument, btnChart);
    }
  }, [dataChart, btnChart]);
  //loading
  useEffect(() => {
    if (isLoadingChart) {
      setTimeout(() => {
        setIsLoadingChart(false);
      }, 1000);
    }
  }, [isLoadingChart]);
  // handle
  // job--------------------------------------------------------------------------
  const handleOpenDropJob = () => {
    if (!isCheckLinkJobRef.current.checked) {
      setIsOpenDropJob(!isOpenDropJob);
    }
  };
  // dop job
  const handleValueDropJob = (e) => {
    setIsOpenDropJob(false);
    setValueDropJob(e.target.innerHTML);
    setIsLoadingJob(true);
    setValueIDDrop(e.target.id);
    hubConnection.invoke('ReadFilesByTimesFinished', e.target.id).catch(console.log);
  };
  // check-box job
  const handleLinkDahboardJob = () => {
    setIsLoadingJob(true);
    setIsCheckJob(!isCheckJob);
    if (isCheckLinkJobRef.current.checked === false) {
      hubConnection.invoke('ReadFilesByTimesFinished', valueIDDrop).catch(console.log);
    }
  };
  // document ----------------------------------------------------------------------
  // open drop
  const handleOpenDropDocument = () => {
    if (!isCheckLinkDocumentRef.current.checked) {
      setIsOpenDropDocument(!isOpenDropDocument);
    }
  };
  // drop document
  const handleValueDropDocument = (e) => {
    setIsOpenDropDocument(false);
    setValueDropDocument(e.target.innerHTML);
    setIsLoadingDocument(true);
    setValueIDDropDocument(e.target.id);
    hubConnection.invoke('ReadFilesByDocumentFinished', e.target.id).catch(console.log);
  };
  // check-box
  const handleLinkDahboardDocument = () => {
    setIsLoadingDocument(true);
    setIsCheckDocument(!isCheckDocument);
    if (isCheckLinkDocumentRef.current.checked === false) {
      hubConnection.invoke('ReadFilesByDocumentFinished', valueIDDropDocument).catch(console.log);
    }
  };
  // chart----------------------------------------------
  const handleValueChart = (typeInput, timeFromIndex = 0, timeToIndex = 0) => {
    istypeRef.current = 1;
    valuebtnChartRef.current = typeInput;
    setIsLoadingChart(true);
    if (isCheckLinkJobRef.current.checked) setIsLoadingJob(true);
    if (isCheckLinkDocumentRef.current.checked) setIsLoadingDocument(true);
    if (typeInput === 'custom-date-chart' && timeFromIndex !== 0 && timeToIndex !== 0) {
      hubConnection.invoke('ReadFilesByTimesCustom', timeFromIndex, timeToIndex).catch(console.log);
      hubConnection.invoke('ReadFilesByTimesCustomDocument', timeFromIndex, timeToIndex).catch(console.log);
    } else {
      console.log(typeInput);
      hubConnection.invoke('ReadFilesByDocument', typeInput).catch(console.log);
      hubConnection.invoke('ReadFilesByTimes', typeInput).catch(console.log);
    }
    setBtnChart(typeInput);
  };
  // return--------------------------------------------------------------------------
  return (
    <div className="row" id="">
      {/* dashboard */}
      <div className="col-md-12 col-xl-8" id="wPnD1">
        <div className={`card sale-card ${isLoadingChart ? 'card-load' : ''} `}>
          {isLoadingChart && (
            <div className="card-loader">
              <i className="feather icon-radio rotate-refresh"></i>
            </div>
          )}
          <div className="card-header">
            <h5>Analysis chart</h5>
          </div>
          <div className="card-block ">
            <div className="button-page">
              <button
                id="OneDay"
                className={`btn-round btn ${btnChart === 'OneDay' ? 'btn-primary' : ''} `}
                onClick={() => handleValueChart('OneDay')}
              >
                Day
              </button>
              <button
                id="OneWeek"
                className={`btn-round btn ${btnChart === 'OneWeek' ? 'btn-primary' : ''} `}
                onClick={() => handleValueChart('OneWeek')}
              >
                Week
              </button>
              <button
                id="OneMonth"
                className={`btn-round btn ${btnChart === 'OneMonth' ? 'btn-primary' : ''} `}
                onClick={() => handleValueChart('OneMonth')}
              >
                Month
              </button>
              <button
                id="OneYear"
                className={`btn-round btn ${btnChart === 'OneYear' ? 'btn-primary' : ''} `}
                onClick={() => handleValueChart('OneYear')}
              >
                Year
              </button>
              <OpenModal
                title="Custom"
                idModal={'custom-date-chart'}
                type={'Dashboard'}
                classStyle={btnChart === 'custom-date-chart' ? 'btn-primary' : ''}
              >
                {innerCustom}
              </OpenModal>
            </div>
            <div id="deal-analytic-chart-2" className="chart-shadow" style={{ height: '351px' }}></div>
          </div>
        </div>
      </div>
      {/* widget */}
      <div className="col-md-12 col-xl-4">
        <div className={`card comp-card ${isLoading ? 'card-load' : ''}`} id="wPnD2">
          {isLoading && (
            <div className="card-loader">
              <i className="feather icon-radio rotate-refresh"></i>
            </div>
          )}
          {/* data New job, Draft Job */}
          <div className="card-body">
            <div className="row align-items-center">
              <div className="col">
                <div className="ds-flex al-center header-widget">
                  <div className="col-6">
                    <h6 className="m-b-25 fw-700">Active job</h6>
                  </div>
                </div>

                <div className="row text-center">
                  <div className="col">
                    <h3 className="f-w-700 text-c-blue">{dataNewJob ? dataNewJob.length : '_'}</h3>
                    <p className="m-b-0 text-c-blue">New job</p>
                  </div>
                  <div className="col">
                    <h3 className="f-w-700 text-c-blue">{dataDraftJob ? dataDraftJob.length : '_'}</h3>
                    <p className="m-b-0 text-c-blue">Draft</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Finish job */}
        <div className={`card comp-card ${isLoadingJob ? 'card-load' : ''}`} id="wPnD22">
          {isLoadingJob && (
            <div className="card-loader">
              <i className="feather icon-radio rotate-refresh"></i>
            </div>
          )}
          <div className="card-body">
            <div className="row align-items-center">
              <div className="col">
                <div className="ds-flex al-center header-widget">
                  <div className="col-6">
                    <h6 className="m-b-25 fw-700">Finished jobs</h6>
                  </div>
                  <div className="col-6">
                    <div className={`dropbox-block drop-first`}>
                      <button
                        className={`btn btn-dropbox ${isCheckLinkJobRef.current.checked ? 'disable' : ''}`}
                        onClick={handleOpenDropJob}
                      >
                        {valueDropJob}
                        {isOpenDropJob ? (
                          <i className="icofont icofont-rounded-down icon-drop"></i>
                        ) : (
                          <i className="icofont icofont-rounded-right icon-drop"></i>
                        )}
                      </button>
                      {isOpenDropJob && (
                        <div className="dropbox-content" onClick={(e) => handleValueDropJob(e)}>
                          <div id="OneDay">Day</div>
                          <div id="OneWeek">Week</div>
                          <div id="OneMonth">Month</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row text-center">
                  <div className="col">
                    <h3 className="f-w-700 text-c-blue">{dataWidgetJob ? dataWidgetJob.length : '_'}</h3>
                    <p className="m-b-0 text-c-blue">Jobs</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col" style={{ position: 'relative' }}>
                    <input
                      type="checkbox"
                      name="link_to_analys_chart"
                      onClick={handleLinkDahboardJob}
                      ref={isCheckLinkJobRef}
                    />
                    <span className="span-checkBox">Link to analysis chart</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* document */}
        <div className={`card comp-card ${isLoadingDocument ? 'card-load' : ''}`} id="wPnD21">
          {/* {isLoadingDocument && (
            <div className="card-loader">
              <i className="feather icon-radio rotate-refresh"></i>
            </div>
          )} */}
          <div className="card-body">
            <div className="row align-items-center">
              <div className="col">
                <div className="ds-flex al-center header-widget">
                  <div className="col-6">
                    <h6 className="m-b-25 fw-700">Document</h6>
                  </div>
                  <div className="col-6">
                    <div className={`dropbox-block drop-first`}>
                      <button
                        className={`btn btn-dropbox ${isCheckLinkDocumentRef.current.checked ? 'disable' : ''}`}
                        onClick={handleOpenDropDocument}
                      >
                        {valueDropDocument}
                        {isOpenDropDocument ? (
                          <i className="icofont icofont-rounded-down icon-drop"></i>
                        ) : (
                          <i className="icofont icofont-rounded-right icon-drop"></i>
                        )}
                      </button>
                      {isOpenDropDocument && (
                        <div className="dropbox-content" onClick={(e) => handleValueDropDocument(e)}>
                          <div id="OneDay">Day</div>
                          <div id="OneWeek">Week</div>
                          <div id="OneMonth">Month</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row text-center">
                  <div className="col">
                    <h3 className="f-w-700 text-c-blue">{dataWidgetDocument ? dataWidgetDocument.length : '_'}</h3>
                    <p className="m-b-0 text-c-blue">Document</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col" style={{ position: 'relative' }}>
                    <input
                      type="checkbox"
                      name="link_to_analys_chart"
                      onClick={handleLinkDahboardDocument}
                      ref={isCheckLinkDocumentRef}
                    />
                    <span className="span-checkBox">Link to analysis chart</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalCustomTimes
        idModal={'custom-date-chart'}
        title="Custom time chart"
        type={'date-pick'}
        // setValueDate={setSetValueDate}
        setInnerCustom={setInnerCustom}
        setTimeFrom={setTimeFrom}
        setTimeTo={setTimeTo}
        handleValueChart={handleValueChart}
      />
    </div>
  );
};

export default Widget;
